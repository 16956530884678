import { useMemo } from "react";
import { LayoutType } from "../../enums/LayoutType";
import { PackagesSearchFormProps } from "../../types/packages/PackagesSearchFormProps";
import TixPackagesSearchForm from "./Tix/PackagesSearchForm";
import ExternalLayoutPackagesSearchForm from "./ExternalLayout/PackagesSearchForm";
import { WizardContextProvider } from "../../contexts/packages/WizardContext";

interface Props extends PackagesSearchFormProps {
  layout: LayoutType;
}

const HotelsSearchForm = ({ layout, ...searchFormProps }: Props) => {
  const Component = useMemo(() => {
    switch (layout) {
      case LayoutType.Tix:
        return TixPackagesSearchForm;
      case LayoutType.External:
        return ExternalLayoutPackagesSearchForm;
      default:
        return TixPackagesSearchForm;
    }
  }, [layout]);
  return (
    <WizardContextProvider>
      <Component {...searchFormProps} />
    </WizardContextProvider>
  );
};

export default HotelsSearchForm;
