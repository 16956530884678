import styled from "styled-components";
import { AutocompletePackageLocationSuggestion } from "../../../../../types/Autocomplete";
import Mk1Typography from "../../../../shared/Mk1Typography";

interface Props {
  onSelect(suggestion: AutocompletePackageLocationSuggestion): void;
  suggestions: AutocompletePackageLocationSuggestion[];
}

const Suggestions = ({ onSelect, suggestions }: Props) => {
  return (
    <Container>
      {suggestions.map((suggestion, index) => (
        <Suggestion key={index} onClick={() => onSelect(suggestion)}>
          <Mk1Typography isHeavy={true}>{suggestion.name}</Mk1Typography>
          <Subtext>{suggestion.description}</Subtext>
        </Suggestion>
      ))}
    </Container>
  );
};

const Container = styled.ul`
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow: auto;
`;

const Suggestion = styled.li`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 10px;
  cursor: pointer;
`;

const Subtext = styled(Mk1Typography)`
  font-size: 11px;
  line-height: 17px;
`;

export default Suggestions;
