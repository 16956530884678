import { forwardRef, HTMLAttributes } from "react";
import styled from "styled-components";
import { Breakpoint } from "../../enums/Breakpoint";
import Button from "./Button";
import CloseIcon from "../../customIcons/Close";
import ErrorMessage from "./ErrorMessage";
import { LayoutType } from "../../enums/LayoutType";

interface Props extends HTMLAttributes<HTMLInputElement> {
  autoFocus?: boolean;
  disabled?: boolean;
  error?: string;
  hasError?: boolean;
  icon?: JSX.Element;
  id?: string;
  label?: string;
  layout?: LayoutType;
  name: string;
  onClear?(): void;
  placeholder?: string | undefined;
  readOnly?: boolean;
  required?: boolean;
  rounded?: boolean;
  type?: "email" | "password" | "text";
  value: string;
}

const TextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      autoFocus,
      disabled,
      error,
      hasError,
      icon,
      id,
      label,
      layout = LayoutType.Tix,
      name,
      onChange,
      onClear,
      onFocus,
      onMouseDown,
      placeholder,
      readOnly,
      required,
      rounded = true,
      type = "text",
      value,
      className,
    },
    ref
  ) => {
    return (
      <FieldContainer
        className={className}
        hasError={hasError || !!error}
        hasIcon={!!icon}
        rounded={rounded}
        layout={layout}
      >
        {icon}
        <input
          ref={ref}
          aria-label={label}
          autoFocus={autoFocus}
          {...(autoFocus && { "data-autofocus": true })}
          disabled={disabled}
          id={id || name}
          name={name}
          type={type}
          placeholder={placeholder || label}
          onFocus={onFocus}
          onMouseDown={onMouseDown}
          onChange={onChange}
          required={required}
          value={value}
          autoCorrect="off"
          autoComplete="off"
          readOnly={readOnly}
        />
        {value && onClear && (
          <ClearButton color="transparent" onClick={onClear} onlyIcon>
            <CloseIcon />
          </ClearButton>
        )}
        {error && <ErrorMessage error={error} />}
      </FieldContainer>
    );
  }
);

const FieldContainer = styled.div<{
  hasError: boolean;
  hasIcon: boolean;
  rounded: boolean;
  layout: LayoutType;
}>`
  align-items: flex-start;
  font-family: ${({ theme }) => theme.regularFont};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > input {
    background: ${({ theme }) => theme.defaultBackground};
    color: ${({ hasError, theme }) => (hasError ? theme.errorColor : theme.defaultColor)};
    padding: ${({ hasIcon }) => (hasIcon ? "0 20px 0 47px" : "0 20px")};
    font-size: ${({ theme }) => theme.form.textField.valueFontSize};
    font-family: ${({ theme, layout }) =>
      layout === LayoutType.External ? theme.heavyFont : theme.regularFont};
    ${({ rounded }) => rounded && " border-radius: 28px;"};
    border: none;
    height: ${({ layout }) => (layout === LayoutType.External ? "70px" : "44px")};
    box-sizing: border-box;
    text-overflow: ellipsis;
    width: 100%;

    &::placeholder {
      color: ${({ hasError, theme }) =>
        hasError ? theme.errorColor : theme.form.textField.placeholderColor};
      opacity: 1;
    }

    @media ${Breakpoint.MinDesktop} {
      height: ${({ layout }) => (layout === LayoutType.External ? "80px" : "48px")};
      padding: ${({ hasIcon }) => (hasIcon ? "0 20px 0 50px" : "0 20px")};
    }
  }

  & > .error-message {
    margin: 10px 0 0 20px;
  }

  & > svg {
    position: absolute;
    top: 24px;
    transform: translateY(-50%);
    left: 20px;
  }
`;

const ClearButton = styled(Button)`
  padding: 0;
  height: 14px;
  width: 14px;
  position: absolute;
  top: 15px;
  right: 20px;
`;

export default TextField;
