import { useMemo } from "react";
import { LayoutType } from "../../enums/LayoutType";
import { HotelsSearchFormProps } from "../../types/HotelsSearchFormProps";
import { HotelsSearchForm as TixHotelsSearchForm } from "./Tix/HotelsSearchForm";
import { HotelsSearchForm as ExternalLayoutHotelsSearchForm } from "./ExternalLayout/HotelsSearchForm";
import { WizardContextProvider } from "../../contexts/hotels/WizardContext";

interface Props extends HotelsSearchFormProps {
  layout: LayoutType;
}

const HotelsSearchForm = ({ layout, ...searchFormProps }: Props) => {
  const Component = useMemo(() => {
    switch (layout) {
      case LayoutType.Tix:
        return TixHotelsSearchForm;
      case LayoutType.External:
        return ExternalLayoutHotelsSearchForm;
      default:
        return TixHotelsSearchForm;
    }
  }, [layout]);
  return (
    <WizardContextProvider>
      <Component {...searchFormProps} />
    </WizardContextProvider>
  );
};

export default HotelsSearchForm;
