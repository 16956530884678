import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import styled from "styled-components";
import { capitalize } from "../../../../../utils/stringUtils";
import RoomField from "./RoomField";
import Mk1Typography from "../../../../shared/Mk1Typography";
import Button from "../../../../shared/Button";
import { Breakpoint } from "../../../../../enums/Breakpoint";
import { HotelsSearchParamsRoom } from "../../../../../types/HotelsSearchParams";
import { useTranslation } from "../../../../../i18n";
import { dispatchCustomEvent } from "../../../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../../../enums/WidgetEvents";

const MIN_NUMBER_OF_ROOMS = 1;
const MAX_NUMBER_OF_ROOMS = 6;

interface Props {
  areChildrenAgeTouched: boolean;
  onChange: (valuesChanges: Record<string, any>, shouldValidate?: boolean) => any;
  values: HotelsSearchParamsRoom[];
}

const ExtendedSearchFields = ({ areChildrenAgeTouched, onChange, values }: Props) => {
  const { t } = useTranslation();

  const addRoom = useCallback(() => {
    if (values.length < MAX_NUMBER_OF_ROOMS) {
      dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.HotelsExtendedSearchAddRoom });
      onChange({ rooms: [...values, { adults: 1, childrenAge: [] }] });
    }
  }, [onChange, values]);

  const removeRoom = useCallback(
    (index: number) => {
      if (values.length >= MIN_NUMBER_OF_ROOMS) {
        dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.HotelsExtendedSearchRemoveRoom });
        onChange({
          rooms: values.filter((room, roomIndex) => index !== roomIndex),
        });
      }
    },
    [onChange, values]
  );

  const handleRoomChange = useCallback(
    (index: number) => (updatedRoom: { adults: number; childrenAge: (number | null)[] }) => {
      onChange({
        rooms: values.map((room, roomIndex) => (roomIndex === index ? updatedRoom : room)),
      });
    },
    [onChange, values]
  );

  const hasLastChildInRoomAgeError = useCallback(
    (room: HotelsSearchParamsRoom) => {
      if (areChildrenAgeTouched) {
        const lastChildAge = [...room.childrenAge].pop();
        return lastChildAge === null;
      }
      return false;
    },
    [areChildrenAgeTouched]
  );

  return (
    <div>
      {values.map((room, index) => {
        return (
          <RoomContainer
            key={index}
            hasError={hasLastChildInRoomAgeError(room)}
            isLastPossible={index + 1 === MAX_NUMBER_OF_ROOMS}
          >
            <RoomHeader>
              <Mk1Typography defaultVariant="normal" isHeavy={true}>{`${capitalize(
                t("tix_search_form_room")
              )} ${index + 1}`}</Mk1Typography>
              {index >= MIN_NUMBER_OF_ROOMS && (
                <RemoveRoomButton onClick={() => removeRoom(index)} color="transparent" onlyIcon>
                  <FontAwesomeIcon icon={faTimes} />
                </RemoveRoomButton>
              )}
            </RoomHeader>
            <RoomField
              areChildrenAgeTouched={areChildrenAgeTouched}
              onChange={handleRoomChange(index)}
              roomIndex={index}
              values={room}
            />
          </RoomContainer>
        );
      })}
      {values.length < MAX_NUMBER_OF_ROOMS && (
        <AddRoomButton onClick={addRoom} type="button">
          {capitalize(t("tix_search_form_add_another_room"))}
        </AddRoomButton>
      )}
    </div>
  );
};

const RoomContainer = styled.div<{
  hasError: boolean;
  isLastPossible: boolean;
}>`
  padding: ${({ hasError }) => (hasError ? "20px 0 0 0" : "20px 0")};
  border-bottom: ${({ isLastPossible, theme }) =>
    isLastPossible ? "none" : `1px solid ${theme.defaultColor}`};
`;

const RoomHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
`;

const RemoveRoomButton = styled(Button).attrs({ variant: "rectangle" })`
  background: transparent;
  width: auto;
  padding: 1px 8px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const AddRoomButton = styled(Button).attrs({ variant: "rectangle" })`
  background: transparent;
  color: ${({ theme }) => theme.form.button.primaryBackground};
  font-size: 13px;
  font-family: ${({ theme }) => theme.heavyFont};
  text-decoration: underline;
  padding: 0;
  margin: 15px 0;

  &:hover:not(:disabled) {
    background: transparent;
  }

  @media ${Breakpoint.MinTablet} {
    margin: 20px 0;
  }
`;

export default ExtendedSearchFields;
