import { FormEvent, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import Autosuggest, {
  InputProps,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
} from "react-autosuggest";
import { usePackageAutocompleteSuggestions } from "../../../../../hooks/packages/usePackageAutocompleteSuggestions";
import InputComponent from "./InputComponent";
import SuggestionsContainer from "./SuggestionsContainer";
import Suggestion from "./Suggestion";
import { AutocompletePackageLocationSuggestion } from "../../../../../types/Autocomplete";

const getSuggestionValue = ({ name }: AutocompletePackageLocationSuggestion) => name;

interface Props {
  error?: string;
  inputLabel: string;
  onSelect(suggestion: AutocompletePackageLocationSuggestion, selectedByHand?: boolean): void;
  value: AutocompletePackageLocationSuggestion;
  inputTitle?: string;
}

const DesktopAutocomplete = forwardRef<HTMLInputElement, Props>(
  ({ error, inputLabel, onSelect, value, inputTitle }: Props, ref) => {
    const { clearSuggestions, fetchSuggestions, suggestions } = usePackageAutocompleteSuggestions();
    const [innerValue, setInnerValue] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      setInnerValue(value.name);
    }, [value.name]);

    const inputProps = useMemo<InputProps<any>>(
      () => ({
        autoComplete: "off",
        autoCorrect: "off",
        name: "locationCode",
        onBlur: (event, { highlightedSuggestion }: any) => {
          if (highlightedSuggestion && innerValue) {
            onSelect(highlightedSuggestion);
          }
          setIsFocused(false);
        },
        onChange: (event, { newValue }) => {
          setInnerValue(newValue);
          if (!newValue) {
            onSelect({ code: "", name: "", type: "", description: "" });
          } else if (!!value.code && newValue !== value.name) {
            onSelect({ code: "", name: newValue, type: "", description: "" });
          }
        },
        onFocus: () => setIsFocused(true),
        required: true,
        value: innerValue,
      }),
      [innerValue, onSelect, value.code, value.name]
    );

    const handleSelect = useCallback(
      (
        event: FormEvent<any>,
        { suggestion }: SuggestionSelectedEventData<AutocompletePackageLocationSuggestion>
      ) => {
        onSelect(suggestion, true);
      },
      [onSelect]
    );

    const fetchSuggestionsRequest = useCallback(
      async ({ value }: SuggestionsFetchRequestedParams) => {
        if (value.length >= 3) {
          fetchSuggestions(value);
        }
      },
      [fetchSuggestions]
    );

    // select after blur if fetching was in progress
    useEffect(() => {
      if (!isFocused && suggestions.length > 0 && value.name !== suggestions[0].name) {
        onSelect(suggestions[0]);
      }
    }, [isFocused, onSelect, suggestions, value]);

    const [isFirstHighlighted, setIsFirstHighlighted] = useState(false);
    const handleSuggestionHighlighted = useCallback(
      ({ suggestion }: { suggestion: AutocompletePackageLocationSuggestion | null }) => {
        setIsFirstHighlighted(
          !!suggestion && !!suggestions[0] && suggestion.code === suggestions[0].code
        );
      },
      [suggestions]
    );

    return (
      <Autosuggest
        focusInputOnSuggestionClick={false}
        highlightFirstSuggestion={true}
        suggestions={suggestions}
        onSuggestionsFetchRequested={fetchSuggestionsRequest}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionSelected={handleSelect}
        onSuggestionHighlighted={handleSuggestionHighlighted}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={Suggestion}
        inputProps={inputProps}
        renderInputComponent={(inputProps: any) => (
          <InputComponent
            {...inputProps}
            error={error}
            inputTitle={inputTitle}
            label={inputLabel}
            placeholder={inputLabel}
            ref={ref}
          />
        )}
        renderSuggestionsContainer={({ containerProps, children, query }) => (
          <SuggestionsContainer
            containerProps={containerProps}
            isFirstHighlighted={isFirstHighlighted}
            query={query}
            title={inputTitle}
            placeholder={innerValue || inputLabel}
          >
            {children}
          </SuggestionsContainer>
        )}
      />
    );
  }
);

export default DesktopAutocomplete;
