import { forwardRef } from "react";
import { InputProps } from "react-autosuggest";
import styled from "styled-components";
import { Breakpoint } from "../../../../../enums/Breakpoint";
import HotelLocationIcon from "../../../../../customIcons/HotelLocation";

interface Props extends InputProps<any> {
  error?: string;
  label: string;
  placeholder?: string;
}

const InputComponent = forwardRef(({ error, label, ...inputProps }: Props, ref) => {
  return (
    <FieldContainer
      hasError={!!error}
      onFocus={(event) => {
        const input = event.currentTarget?.querySelector("input");
        setTimeout(() => {
          input && input.select();
        });
      }}
    >
      <HotelLocationIcon />
      <input ref={ref} aria-label={label} {...(inputProps as any)} />
    </FieldContainer>
  );
});

const FieldContainer = styled.div<{ direction?: any; hasError: boolean }>`
  font-family: ${({ theme }) => theme.regularFont};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${Breakpoint.MinDesktop} {
    &::after {
      border-right: 1px solid ${({ theme }) => theme.defaultColor};
      content: "";
      height: 20px;
      position: absolute;
      right: 1px;
      top: 14px;
    }
  }

  & > input {
    background: ${({ theme }) => theme.defaultBackground};
    color: ${({ hasError, theme }) => (hasError ? theme.errorColor : theme.defaultColor)};
    padding: 0 0 0 50px;
    font-size: 14px;
    font-family: inherit;
    border-radius: 28px;
    border: none;
    height: 44px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    width: 100%;

    &::placeholder {
      color: ${({ hasError, theme }) =>
        hasError ? theme.errorColor : theme.form.textField.placeholderColor};
      opacity: 1;
    }

    @media ${Breakpoint.MinDesktop} {
      /* border-radius: 28px 0 0 28px; */
      border-radius: 0;
      height: 48px;
      /* padding: ${({ direction }) =>
        direction === "departure" ? "0 42px 0 50px" : "0 9px 0 43px"}; */
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;

    @media ${Breakpoint.MinDesktop} {
      /* left: ${({ direction }) => (direction === "destination" ? "10px" : "20px")}; */
    }
  }
`;

export default InputComponent;
