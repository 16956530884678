import { CommunicationType } from "../enums/CommunicationType";
import { LayoutType } from "../enums/LayoutType";
import { SearchWidgetType } from "../enums/SearchWidgetType";
import { ThemeType } from "../enums/ThemeType";
import { Language } from "./Language";

export interface AppAttributes {
  language: Language;
  layout: LayoutType;
  theme: ThemeType;
  widgetType: SearchWidgetType;
  mobileModalTopValue: string;
  hotelsSrpPath: string;
  flightsSrpPath: string;
  packagesSrpPath: string;
  packagesEnabled: boolean;
  communicationType: CommunicationType;
}

export enum ElementAppAttribute {
  Language = "data-widget-language",
  LayoutType = "data-widget-layout",
  ThemeType = "data-widget-theme",
  WidgetType = "data-widget-type",
  MobileModalTopOffset = "data-mobile-modal-top-offset-px",
  HotelsSrpPath = "data-hotels-srp-path",
  FlightsSrpPath = "data-flights-srp-path",
  PackagesSrpPath = "data-packages-srp-path",
  PackagesEnabled = "data-packages-enabled",
  CommunicationType = "data-communication-type",
}
