import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { i18n as i18nType } from "i18next";
import styled, { ThemeProvider } from "styled-components";
import { useAppAttributes } from "./hooks/useAppAttributes";
import createI18N from "./i18n";
import { Breakpoint } from "./enums/Breakpoint";
import useMedia from "./hooks/useMedia";
import { I18NNamespace } from "./enums/I18N";
import BaseContainer from "./components/shared/BaseContainer";
import SearchFormTabs from "./components/SearchFormTabs";
import { SearchFormTabValues } from "./enums/SearchFormTabValues";
import HotelsSearchForm from "./components/HotelsSearchForm/HotelsSearchForm";
import { themes } from "./themes/themes";
import GlobalStyles from "./components/GlobalStyles";
import { SearchWidgetType } from "./enums/SearchWidgetType";
import { FlightsSearchCriteria } from "./types/FlightsSearchCriteria";
import { useFlightsSearchForm } from "./hooks/useFlightsSearchForm";
import { useFlightsSearchCriteria } from "./hooks/useFlightsSearchCriteria";
import { HotelsSearchParams } from "./types/HotelsSearchParams";
import { HotelsSearchCriteria } from "./types/HotelsSearchCriteria";
import { useHotelsSearchForm } from "./hooks/useHotelsSearchForm";
import FlightsSearchForm from "./components/FlightsSearchForm/FlightsSearchForm";
import { LayoutType } from "./enums/LayoutType";
import { useQueryParams } from "./hooks/useQueryParams";
import SearchFormWithFocusScrollContext from "./contexts/SearchFormWithFocusScrollContext";
import PackagesSearchForm from "./components/PackagesSearchForm/PackagesSearchForm";
import { PackagesSearchParams } from "./types/packages/PackagesSearchParams";
import { usePackagesSearchForm } from "./hooks/packages/usePackagesSearchForm";
import { PackagesSearchCriteria } from "./types/packages/PackagesSearchCriteria";

const App: React.FC = () => {
  const appAttributes = useAppAttributes();
  const isMobile = useMedia(Breakpoint.MaxMobile);
  const { queryParams, patchQueryParams } = useQueryParams<{
    formType: SearchFormTabValues;
  }>();
  const enabledTabs = useMemo(() => {
    const enabledTabs = [SearchFormTabValues.Flights, SearchFormTabValues.Hotels];
    if (appAttributes.packagesEnabled) {
      enabledTabs.push(SearchFormTabValues.Packages);
    }
    return enabledTabs;
  }, [appAttributes.packagesEnabled]);
  const [activeTab, setActiveTab] = useState(
    queryParams.formType && enabledTabs.includes(queryParams.formType)
      ? queryParams.formType
      : SearchFormTabValues.Flights
  );
  const handleActiveTabChange = useCallback(
    (tab: SearchFormTabValues) => {
      setActiveTab(tab);
      patchQueryParams({ formType: tab });
    },
    [patchQueryParams]
  );
  const { searchCriteria } = useFlightsSearchCriteria(false);

  const handleFlightsSearch = useCallback(
    (values) => {
      const newSearchCriteria = new FlightsSearchCriteria(values);
      window.location.assign(
        `${appAttributes.flightsSrpPath}?${newSearchCriteria.toQueryString()}`
      );
    },
    [appAttributes]
  );
  const flightsSearchForm = useFlightsSearchForm(handleFlightsSearch, searchCriteria, false);

  const handleHotelsSearch = useCallback(
    async (values: HotelsSearchParams) => {
      const searchCriteria = new HotelsSearchCriteria(values);
      window.location.assign(`${appAttributes.hotelsSrpPath}?${searchCriteria.toQueryString()}`);
    },
    [appAttributes]
  );
  const hotelsSearchForm = useHotelsSearchForm(handleHotelsSearch);

  const handlePackagesSearch = useCallback(
    async (values: PackagesSearchParams) => {
      const searchCriteria = new PackagesSearchCriteria(values);
      window.location.assign(`${appAttributes.packagesSrpPath}?${searchCriteria.toQueryString()}`);
    },
    [appAttributes]
  );
  const packagesSearchForm = usePackagesSearchForm(handlePackagesSearch);

  // i18n
  const [i18n, setI18N] = useState<i18nType | undefined>();
  useEffect(() => {
    const namespace = isMobile ? I18NNamespace.Mobile : I18NNamespace.General;
    if (appAttributes) {
      if (i18n && !i18n.options.ns?.includes(namespace)) {
        i18n.loadNamespaces(namespace);
      } else {
        createI18N(appAttributes.language, namespace).then((i18n) => setI18N(i18n));
      }
    }
  }, [appAttributes, i18n, isMobile]);

  const theme = useMemo(() => appAttributes && themes[appAttributes.theme], [appAttributes]);

  const searchWidget = useMemo(() => {
    switch (appAttributes?.widgetType) {
      case SearchWidgetType.Combined:
        return (
          <SearchFormTabs
            activeTab={activeTab}
            onChange={handleActiveTabChange}
            layout={appAttributes.layout}
          >
            {activeTab === SearchFormTabValues.Flights && (
              <FlightsSearchForm
                layout={appAttributes.layout}
                errors={flightsSearchForm.errors}
                hasErrors={flightsSearchForm.hasErrors}
                touched={flightsSearchForm.touched}
                onChange={flightsSearchForm.setFieldsValues}
                onSubmit={flightsSearchForm.handleSubmit}
                values={flightsSearchForm.values}
              />
            )}
            {activeTab === SearchFormTabValues.Hotels && (
              <HotelsSearchForm
                layout={appAttributes.layout}
                errors={hotelsSearchForm.errors}
                hasErrors={hotelsSearchForm.hasErrors}
                touched={hotelsSearchForm.touched}
                onChange={hotelsSearchForm.setFieldsValues}
                onSubmit={hotelsSearchForm.handleSubmit}
                values={hotelsSearchForm.values}
              />
            )}
            {appAttributes.packagesEnabled && activeTab === SearchFormTabValues.Packages && (
              <PackagesSearchForm
                layout={appAttributes.layout}
                errors={packagesSearchForm.errors}
                hasErrors={packagesSearchForm.hasErrors}
                touched={packagesSearchForm.touched}
                onChange={packagesSearchForm.setFieldsValues}
                onSubmit={packagesSearchForm.handleSubmit}
                values={packagesSearchForm.values}
              />
            )}
          </SearchFormTabs>
        );
      case SearchWidgetType.Flights:
        return (
          <FlightsSearchForm
            layout={appAttributes.layout}
            errors={flightsSearchForm.errors}
            hasErrors={flightsSearchForm.hasErrors}
            touched={flightsSearchForm.touched}
            onChange={flightsSearchForm.setFieldsValues}
            onSubmit={flightsSearchForm.handleSubmit}
            values={flightsSearchForm.values}
          />
        );
      case SearchWidgetType.Hotels:
        return (
          <HotelsSearchForm
            layout={appAttributes.layout}
            errors={hotelsSearchForm.errors}
            hasErrors={hotelsSearchForm.hasErrors}
            touched={hotelsSearchForm.touched}
            onChange={hotelsSearchForm.setFieldsValues}
            onSubmit={hotelsSearchForm.handleSubmit}
            values={hotelsSearchForm.values}
          />
        );
      case SearchWidgetType.Packages:
        return (
          <PackagesSearchForm
            layout={appAttributes.layout}
            errors={packagesSearchForm.errors}
            hasErrors={packagesSearchForm.hasErrors}
            touched={packagesSearchForm.touched}
            onChange={packagesSearchForm.setFieldsValues}
            onSubmit={packagesSearchForm.handleSubmit}
            values={packagesSearchForm.values}
          />
        );
      default:
        return <></>;
    }
  }, [
    appAttributes,
    activeTab,
    flightsSearchForm,
    hotelsSearchForm,
    packagesSearchForm,
    handleActiveTabChange,
  ]);

  const searchFormElementRef = useRef(null);

  return (
    <div id="tix-search-form" className="tix-search-form">
      {i18n && theme && appAttributes && (
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <Container layout={appAttributes.layout}>
              <SearchFormWithFocusScrollContext.Provider value={{ searchFormElementRef }}>
                <SearchFormContainer layout={appAttributes.layout} ref={searchFormElementRef}>
                  {searchWidget}
                </SearchFormContainer>
              </SearchFormWithFocusScrollContext.Provider>
            </Container>
            <GlobalStyles layout={appAttributes?.layout} />
          </ThemeProvider>
        </I18nextProvider>
      )}
    </div>
  );
};

const Container = styled.div<{ layout?: LayoutType }>`
  width: 100%;
  font-family: ${({ theme }) => theme.regularFont};
`;

const SearchFormContainer = styled(BaseContainer)`
  z-index: 7;
`;

export default App;
