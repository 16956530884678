import { addDays, startOfToday } from "date-fns";
import * as Yup from "yup";

export const ALLOWED_DAYS_IN_FUTURE = 361;
export const ALLOWED_DAYS_PERIOD = 30;

export const validationSchema = Yup.object({
  departureDate: Yup.date()
    .required("tix_search_form_err_departure_date_is_empty")
    .min(startOfToday(), "tix_search_form_err_departure_date_is_from_past")
    .max(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE), "tix_search_form_err_latest_date"),
  returnDate: Yup.date()
    .required("tix_search_form_err_return_date_is_empty")
    .min(startOfToday(), "tix_search_form_err_return_date_is_from_past")
    .max(addDays(new Date(), ALLOWED_DAYS_IN_FUTURE), "tix_search_form_err_latest_date")
    .when(
      "departureDate",
      (departureDate: string, yup) =>
        departureDate &&
        yup.max(
          addDays(new Date(departureDate), ALLOWED_DAYS_PERIOD),
          "tix_search_form_err_package_dates_max_period_exceeded"
        )
    ),
  departureAirportCode: Yup.string().required("tix_search_form_err_package_departure_airport_is_empty"),
  destinationCode: Yup.string().required("tix_search_form_err_package_destination_is_empty"),
  destinationType: Yup.string(),
  rooms: Yup.array().of(
    Yup.object().shape({
      adults: Yup.number(),
      childrenAge: Yup.array().of(Yup.number()),
    })
  ),
});
