import { AppAttributes, ElementAppAttribute } from "./../types/AppAttributes";
import { useCallback, useMemo } from "react";
import { widgetMainDocument } from "../utils/widgetParentUtils";
import {
  DEFAULT_COMMUNICATION_TYPE,
  DEFAULT_FLIGHTS_SRP_PATH,
  DEFAULT_HOTELS_SRP_PATH,
  DEFAULT_LANG,
  DEFAULT_LAYOUT,
  DEFAULT_MOBILE_TOP_OFFSET,
  DEFAULT_PACKAGES_SRP_PATH,
  DEFAULT_THEME,
  DEFAULT_WIDGET_TYPE,
} from "../constants/appContants";
import { Language } from "../types/Language";
import { CommunicationType } from "../enums/CommunicationType";
import { LayoutType } from "../enums/LayoutType";
import { ThemeType } from "../enums/ThemeType";
import { SearchWidgetType } from "../enums/SearchWidgetType";

const TRUE_VALUE = "true";
let memoizedAppAttributes: AppAttributes;

export const useAppAttributes = () => {
  const element = widgetMainDocument.querySelector(
    `#${process.env.REACT_APP_CONTAINER_ELEMENT_ID}`
  );

  const getValidAppAttribute = useCallback(
    (appAttribute: ElementAppAttribute) => {
      const value = element && element.getAttribute(appAttribute);
      switch (appAttribute) {
        case ElementAppAttribute.Language:
          return Object.values(Language).includes(value as Language) ? value : DEFAULT_LANG;
        case ElementAppAttribute.CommunicationType:
          return Object.values(CommunicationType).includes(value as CommunicationType)
            ? value
            : DEFAULT_COMMUNICATION_TYPE;
        case ElementAppAttribute.FlightsSrpPath:
          return value?.length || 0 > 1 ? value : DEFAULT_FLIGHTS_SRP_PATH;
        case ElementAppAttribute.HotelsSrpPath:
          return value?.length || 0 > 1 ? value : DEFAULT_HOTELS_SRP_PATH;
        case ElementAppAttribute.PackagesSrpPath:
          return value?.length || 0 > 1 ? value : DEFAULT_PACKAGES_SRP_PATH;
        case ElementAppAttribute.PackagesEnabled:
          return value === TRUE_VALUE;
        case ElementAppAttribute.LayoutType:
          return Object.values(LayoutType).includes(value as LayoutType) ? value : DEFAULT_LAYOUT;
        case ElementAppAttribute.ThemeType:
          return Object.values(ThemeType).includes(value as ThemeType) ? value : DEFAULT_THEME;
        case ElementAppAttribute.WidgetType:
          return Object.values(SearchWidgetType).includes(value as SearchWidgetType)
            ? value
            : DEFAULT_WIDGET_TYPE;
        case ElementAppAttribute.MobileModalTopOffset:
          const intValue = parseInt(value || "");
          return !isNaN(intValue) ? `${intValue}px` : DEFAULT_MOBILE_TOP_OFFSET;
      }
    },
    [element]
  );

  const appAttributes = useMemo(() => {
    if (!memoizedAppAttributes) {
      if (element) {
        memoizedAppAttributes = {
          language: getValidAppAttribute(ElementAppAttribute.Language),
          layout: getValidAppAttribute(ElementAppAttribute.LayoutType),
          theme: getValidAppAttribute(ElementAppAttribute.ThemeType),
          widgetType: getValidAppAttribute(ElementAppAttribute.WidgetType),
          mobileModalTopValue: getValidAppAttribute(ElementAppAttribute.MobileModalTopOffset),
          hotelsSrpPath: getValidAppAttribute(ElementAppAttribute.HotelsSrpPath),
          flightsSrpPath: getValidAppAttribute(ElementAppAttribute.FlightsSrpPath),
          packagesSrpPath: getValidAppAttribute(ElementAppAttribute.PackagesSrpPath),
          packagesEnabled: getValidAppAttribute(ElementAppAttribute.PackagesEnabled),
          communicationType: getValidAppAttribute(ElementAppAttribute.CommunicationType),
        } as AppAttributes;
      }
    }
    return memoizedAppAttributes;
  }, [element, getValidAppAttribute]);

  return appAttributes;
};
