import styled from "styled-components";
import CalendarDropdown from "./components/CalendarDropdown";
import DoubleMonthDayPicker from "../DayPickers/DoubleMonthDayPicker/DoubleMonthDayPicker";
import CalendarDropdownIndicator from "./components/CalendarDropdownIndicator";
import { DEFAULT_DISABLED_DAYS } from "../../../constants/flightsConstants";
import useMedia from "../../../hooks/useMedia";
import { Breakpoint } from "../../../enums/Breakpoint";
import useMeasure, { RectReadOnly } from "react-use-measure";
import { useMemo } from "react";

interface Props {
  isToOpen: boolean;
  disabledDays?: { before: Date; after: Date };
  fromDate?: Date;
  toDate?: Date;
  shouldShowRange?: boolean;
  initialMonth: Date;
  onDayClick: (day: Date) => void;
  titleLabelKey?: string;
  buttonContainerBounds: RectReadOnly;
  isSingleField?: boolean;
}

const DoubleMonthDropdown = ({
  isToOpen,
  disabledDays = DEFAULT_DISABLED_DAYS,
  fromDate,
  toDate,
  shouldShowRange,
  initialMonth,
  onDayClick,
  titleLabelKey,
  buttonContainerBounds,
  isSingleField,
}: Props) => {
  const isMinDesktop = useMedia(Breakpoint.MinDesktop);
  const [dropdownRef, dropdownBounds] = useMeasure();
  const isDropdownInViewport = useMemo(() => {
    if (!isMinDesktop) return true;
    return (
      buttonContainerBounds.left + buttonContainerBounds.width / 2 - dropdownBounds.width / 2 >= 0
    );
  }, [buttonContainerBounds.left, buttonContainerBounds.width, dropdownBounds.width, isMinDesktop]);

  const dropdownToButtonOffset = useMemo(() => {
    return buttonContainerBounds.left - dropdownBounds.left;
  }, [buttonContainerBounds.left, dropdownBounds.left]);

  return (
    <StyledDropdown
      ref={dropdownRef}
      buttonContainerHeight={buttonContainerBounds.height}
      isDropdownInViewport={isDropdownInViewport}
      isVisible={dropdownBounds.width > 0}
    >
      <StyledIndicator
        isToOpen={isToOpen}
        isDropdownInViewport={isDropdownInViewport}
        isSingleField={isSingleField}
        dropdownToButtonOffset={dropdownToButtonOffset}
      />
      <DoubleMonthDayPicker
        onDayClick={onDayClick}
        disabledDays={disabledDays}
        fromDate={fromDate}
        toDate={toDate}
        shouldShowRange={shouldShowRange}
        initialMonth={initialMonth}
        titleLabelKey={titleLabelKey}
      />
    </StyledDropdown>
  );
};

const StyledDropdown = styled(CalendarDropdown)<{
  buttonContainerHeight: number;
  isDropdownInViewport: boolean;
  isVisible: boolean;
}>`
  /* top: 66px;
  left: 50%;
  transform: translateX(-50%); */
  top: ${({ buttonContainerHeight }) => `${buttonContainerHeight + 18}px`};
  left: 20px;

  @media ${Breakpoint.MinDesktop} {
    visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
    ${({ isDropdownInViewport }) =>
      isDropdownInViewport
        ? `
          left: 50%;
          transform: translateX(-50%);
          `
        : `left: 20px;`}
  }
`;

const StyledIndicator = styled(CalendarDropdownIndicator)<{
  isToOpen: boolean;
  isDropdownInViewport: boolean;
  isRangePicker?: boolean;
  isSingleField?: boolean;
  dropdownToButtonOffset: number;
}>`
  top: -13px;
  left: ${({ isSingleField, isDropdownInViewport, isToOpen, dropdownToButtonOffset }) => {
    if (isSingleField) {
      if (isDropdownInViewport) {
        return isToOpen
          ? `calc(${dropdownToButtonOffset + 165}px)`
          : `calc(${dropdownToButtonOffset + 85}px)`;
      }
      return isToOpen ? "145px" : "60px";
    }
    return isToOpen ? "calc(50% + 75px)" : "calc(50% - 75px)";
  }};
  transform: translate(-50%);
`;

export default DoubleMonthDropdown;
