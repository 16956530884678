import { FormikErrors, FormikTouched } from "formik";
import { ChangeEvent, useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import RadioGroupField from "../../shared/RadioGroupField";
import Switcher from "../Switcher";
import {
  FlightsSearchFormValues,
  FlightsSearchFormValuesType as FlightType,
} from "../../../types/FlightsSearchFormValues";
import SearchFormRow from "./SearchFormRow";
import Button from "../../shared/Button";
import useMedia from "../../../hooks/useMedia";
import ErrorMessage from "../../shared/ErrorMessage";
import { useTranslation } from "../../../i18n";
import ExtendedFlightSearch from "./ExtendedFlightSearch";
import { WidgetGATriggerEvents } from "../../../enums/WidgetEvents";
import { dispatchCustomEvent } from "../../../services/widgetEventsService";
import ExternalLayoutPerks from "../../shared/ExternalLayout/ExternalLayoutPerks";
import { Breakpoint } from "../../../enums/Breakpoint";
import { LayoutType } from "../../../enums/LayoutType";
import { InputKey, useWizardContext } from "../../../contexts/flights/WizardContext";

export const EMPTY_BOUND = {
  departureCode: "",
  departureName: "",
  departureDate: undefined,
  destinationCode: "",
  destinationName: "",
};

const BOUNDS_NUMBER = {
  [FlightType.OneWay]: 1,
  [FlightType.Return]: 2,
  [FlightType.MultiCity]: 2,
};

interface Props {
  errors: FormikErrors<FlightsSearchFormValues>;
  hasErrors: boolean;
  touched: FormikTouched<FlightsSearchFormValues>;
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  onSubmit(): void;
  values: FlightsSearchFormValues;
  scrollControlled?: boolean;
}

export const FlightsSearchForm = ({
  errors,
  hasErrors,
  touched,
  onChange,
  onSubmit,
  values,
  scrollControlled,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMinTablet = useMedia(theme.breakpoints.minTablet);
  const isMinDesktop = useMedia(theme.breakpoints.minDesktop);
  const isOnlyTablet = useMedia(theme.breakpoints.onlyTablet);
  const { inputRefs, isWizard } = useWizardContext();

  const typeOptions = useMemo(
    () => [
      { label: t("tix_search_form_return"), value: FlightType.Return },
      { label: t("tix_search_form_oneway"), value: FlightType.OneWay },
      { label: t("tix_search_form_multicity"), value: FlightType.MultiCity },
    ],
    [t]
  );
  const handleTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const bounds =
        event.target.value === FlightType.Return
          ? [
              values.bounds[0],
              {
                departureCode: values.bounds[0].destinationCode,
                departureName: values.bounds[0].destinationName,
                departureDate: values.bounds[1]?.departureDate,
                destinationCode: values.bounds[0].departureCode,
                destinationName: values.bounds[0].departureName,
              },
            ]
          : new Array(BOUNDS_NUMBER[event.target.value as FlightType])
              .fill(null)
              .map((_, index) => values.bounds[index] || EMPTY_BOUND);
      const selectionEvent =
        event.target.value === FlightType.Return
          ? WidgetGATriggerEvents.ReturnSelected
          : event.target.value === FlightType.OneWay
          ? WidgetGATriggerEvents.OneWaySelected
          : WidgetGATriggerEvents.MulticitySelected;
      dispatchCustomEvent({ widgetEvent: selectionEvent });
      onChange({ type: event.target.value, bounds });
    },
    [onChange, values.bounds]
  );

  const numberOfFieldsInRow = useMemo(() => {
    if (isMinDesktop) {
      return values.type === FlightType.MultiCity ? 4 : 5;
    }
    if (isOnlyTablet) {
      return values.type === FlightType.MultiCity ? 3 : 2;
    }
    return 1;
  }, [isMinDesktop, isOnlyTablet, values.type]);

  const handleDateSelect = useCallback(
    (rowIndex: number) => () => {
      switch (rowIndex) {
        case 0: {
          if (values.type === FlightType.MultiCity && !values.bounds[1].departureCode) {
            inputRefs.current.secondDepartureCode?.focus();
          } else if (values.type !== FlightType.MultiCity && isWizard) {
            inputRefs.current.extendedSearch?.click();
          }
          break;
        }
        case 1: {
          if (isWizard) {
            inputRefs.current.extendedSearch?.click();
          }
          break;
        }
      }
    },
    [inputRefs, isWizard, values.bounds, values.type]
  );

  return (
    <ExternalLayoutFormContainer>
      <form onSubmit={onSubmit} noValidate>
        <Row>
          {isMinTablet ? (
            <RadioGroupField
              inline
              label={t("tix_search_form_flight_type")}
              onChange={handleTypeChange}
              name="type"
              options={typeOptions}
              value={values.type}
            />
          ) : (
            <SwitcherWrapper>
              <Switcher
                label={t("tix_search_form_flight_type")}
                onChange={handleTypeChange}
                name="type"
                options={typeOptions}
                value={values.type}
              />
            </SwitcherWrapper>
          )}
        </Row>
        <SearchFormWrapper>
          <SearchFormMainSection maxItemsPerRow={numberOfFieldsInRow}>
            <SearchFormRow
              boundsIndexes={values.type === FlightType.Return ? [0, 1] : [0]}
              errors={errors}
              touched={touched}
              isLastRow={values.type !== FlightType.MultiCity}
              onChange={onChange}
              onDateSelect={handleDateSelect(0)}
              values={values}
              scrollControlled={scrollControlled}
              inline={values.type === FlightType.MultiCity || isMinDesktop}
              departureCodeInputKey={InputKey.FirstDepartureCode}
            />
            {values.type === FlightType.MultiCity && isMinDesktop && (
              <ExtendedFlightSearch
                onChange={onChange}
                values={values}
                scrollControlled={scrollControlled}
                onSubmit={onSubmit}
                isMulticity={values.type === FlightType.MultiCity}
              />
            )}
            {values.type === FlightType.MultiCity && (
              <SearchFormRow
                boundsIndexes={[1]}
                errors={errors}
                touched={touched}
                isLastRow
                onChange={onChange}
                onDateSelect={handleDateSelect(1)}
                scrollControlled={scrollControlled}
                values={values}
                inline={values.type === FlightType.MultiCity || isMinDesktop}
                departureCodeInputKey={InputKey.SecondDepartureCode}
              />
            )}
            {!(values.type === FlightType.MultiCity && isMinDesktop) && (
              <ExtendedFlightSearch
                onChange={onChange}
                values={values}
                scrollControlled={scrollControlled}
                onSubmit={onSubmit}
                isMulticity={values.type === FlightType.MultiCity}
              />
            )}
            <SubmitButton
              variant="rectangle"
              type="submit"
              onClick={() => {
                onSubmit();
              }}
              size={isMinDesktop ? "medium" : "small"}
            >
              {t("tix_search_form_search_flights")}
            </SubmitButton>
          </SearchFormMainSection>
        </SearchFormWrapper>
        {hasErrors && (
          <FormErrorMessage>
            <ErrorMessage
              error="tix_search_form_err_flights_search_form_global"
              layout={LayoutType.External}
            />
          </FormErrorMessage>
        )}
      </form>
      <ExternalLayoutPerks />
    </ExternalLayoutFormContainer>
  );
};

const ExternalLayoutFormContainer = styled.div`
  padding: 20px 0 16px 0;
  background: ${({ theme }) => theme.darkBackground};

  @media ${Breakpoint.MinTablet} {
    border-radius: ${({ theme }) => theme.ovalBorderRadius};
    padding: 10px 0 12px 0;
  }

  @media ${Breakpoint.MaxMobile} {
    ${({ theme }) =>
      !!theme.form.container?.mobileBackgroundColor &&
      `background: ${theme.form.container?.mobileBackgroundColor};`}
  }
`;

const SearchFormWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;

  @media ${({ theme }) => theme.breakpoints.maxMobile} {
    padding: 0 20px;
  }
`;

const SearchFormMainSection = styled.div<{ maxItemsPerRow: number }>`
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  > * {
    border-radius: ${({ theme }) => theme.ovalBorderRadius};
    flex: 1;
    ${({ maxItemsPerRow }) =>
      `min-width: calc((100% - ${(maxItemsPerRow - 1) * 10}px) / ${maxItemsPerRow})`};
  }

  @media ${({ theme }) => theme.breakpoints.maxMobile} {
    gap: 5px;
  }
`;

const FormErrorMessage = styled.div`
  display: inline-flex;
  margin: 10px 0 10px 20px;
`;

const Row = styled.div`
  margin: 0 0 10px 0;

  @media ${({ theme }) => theme.breakpoints.minTablet} {
    margin: 0 0 10px 10px;
  }
`;

const SwitcherWrapper = styled.div`
  margin: 0 20px 20px 20px;
`;

const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.flightSearchForm.buttonColor};
  padding: 0;
  font-family: ${({ theme }) => theme.heavyFont};

  @media ${({ theme }) => theme.breakpoints.maxMobile} {
    && {
      height: 70px;
      font-size: 13px;
    }
  }

  @media ${({ theme }) => theme.breakpoints.minTablet} {
    && {
      height: 80px;
      font-size: 20px;
    }
  }
`;

export default FlightsSearchForm;
