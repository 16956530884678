import {
  CSSProperties,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import FocusLock from "react-focus-lock";
import { createPortal } from "react-dom";
import styled, { Keyframes, css } from "styled-components";
import CloseIcon from "../../../customIcons/Close";
import { disableScroll, enableScroll } from "../../../utils/scrollLock";
import NexaTypography from "../NexaTypography";
import Button from "../Button";
import { useAppAttributes } from "../../../hooks/useAppAttributes";
import { widgetMainDocument } from "../../../utils/widgetParentUtils";
import { LayoutType } from "../../../enums/LayoutType";
import Mk1Typography from "../Mk1Typography";
import { KEYFRAMES } from "./keyframes";
import usePrevious from "../../../hooks/usePrevious";
import { MobileModalAnimations } from "../../../types/MobileModal";

export const SLIDE_ANIMATION_DURATION = 1000;
const FADE_ANIMATION_DURATION = 500;
const REMOVE_MODAL_TIMEOUT = 500;
const DEFAULT_CLOSING_ANIMATION = KEYFRAMES.fadeOut;

type AnimationsConfig = {
  open: Keyframes;
  openDuration: number;
  close: Keyframes;
  closeDuration: number;
};

const createAnimationsKeyframes = (animations?: MobileModalAnimations): AnimationsConfig => ({
  open: KEYFRAMES[`${animations?.open || "fade"}In`],
  openDuration: animations?.open === "slide" ? SLIDE_ANIMATION_DURATION : FADE_ANIMATION_DURATION,
  close: KEYFRAMES[`${animations?.close || "fade"}Out`],
  closeDuration: animations?.close === "slide" ? SLIDE_ANIMATION_DURATION : FADE_ANIMATION_DURATION,
});

interface Props {
  action: { label: string; onClick: () => void };
  animations?: MobileModalAnimations;
  headerTitle?: string;
  headerContent?: JSX.Element;
  headerIcon?: JSX.Element;
  hiddenHeader?: boolean;
  id?: string;
  isOpened: boolean;
  onClose(): void;
  style?: {
    modal?: CSSProperties;
    header?: CSSProperties;
    content?: CSSProperties;
  };
  className?: string;
  scrollControlled?: boolean;
  layout?: LayoutType;
  isWizard?: boolean;
}

const MobileModal: FC<PropsWithChildren<Props>> = ({
  action,
  animations,
  children,
  headerTitle,
  headerContent,
  headerIcon,
  hiddenHeader,
  id,
  isOpened,
  onClose,
  style,
  className,
  scrollControlled,
  layout,
  isWizard,
}) => {
  const appAttributes = useAppAttributes();

  // delay for closing animation
  const [isVisible, setIsVisible] = useState(false);
  const prevIsVisible = usePrevious(isVisible);
  const [wasManuallyClosed, setWasManuallyClosed] = useState(false);

  const Typography = useMemo(
    () => (appAttributes.layout === LayoutType.External ? Mk1Typography : NexaTypography),
    [appAttributes.layout]
  );

  const HeaderText = styled(Typography)`
    width: calc(100% - 60px);
    margin: auto 0 20px 20px;
    display: flex;

    svg {
      margin-right: 5px;
    }
  `;

  // disabling body scroll
  useEffect(() => {
    if (!scrollControlled) {
      if (isVisible && !prevIsVisible) {
        disableScroll();
      } else if (!isVisible && prevIsVisible && !isWizard) {
        enableScroll();
      }
    }
  }, [isVisible, scrollControlled, isWizard, prevIsVisible]);
  useEffect(() => {
    return () => {
      enableScroll();
    };
  }, []);

  // close on escape
  const closeOnEsc = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );
  useEffect(() => {
    if (isOpened) {
      document.addEventListener("keydown", closeOnEsc);
    }
    return () => {
      document.removeEventListener("keydown", closeOnEsc);
    };
  }, [isOpened, closeOnEsc]);

  // Wizard animations
  const animationConfig = useMemo(
    () =>
      ({
        ...createAnimationsKeyframes(animations),
        ...(wasManuallyClosed
          ? { close: DEFAULT_CLOSING_ANIMATION, closeDuration: FADE_ANIMATION_DURATION }
          : {}),
      } as AnimationsConfig),
    [animations, wasManuallyClosed]
  );

  useEffect(() => {
    let timeout: any = null;
    if (isOpened) {
      setIsVisible(true);
    } else {
      timeout = setTimeout(() => {
        setIsVisible(false);
        setWasManuallyClosed(false);
      }, animationConfig.closeDuration + REMOVE_MODAL_TIMEOUT);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [animationConfig.closeDuration, isOpened, setIsVisible]);

  return createPortal(
    <>
      {isVisible && (
        <FocusLock>
          <Container
            id={id}
            className={className}
            topOffset={appAttributes?.mobileModalTopValue || "0px"}
          >
            <ModalElement animations={animationConfig} isVisible={isOpened} style={style?.modal}>
              {!hiddenHeader && (
                <Header style={style?.header}>
                  {headerTitle && (
                    <HeaderText>
                      {headerIcon}
                      {headerTitle}
                    </HeaderText>
                  )}
                  {headerContent && (
                    <HeaderContent>
                      {headerIcon}
                      {headerContent}
                    </HeaderContent>
                  )}

                  <CloseButton layout={layout} onlyIcon color="transparent" onClick={onClose}>
                    <CloseIcon
                      width={layout === LayoutType.External ? 15 : 12}
                      height={layout === LayoutType.External ? 15 : 12}
                      strokeWidth={layout === LayoutType.External ? 2 : 1}
                    />
                  </CloseButton>
                </Header>
              )}
              <Content style={style?.content}>{children}</Content>
              {action && (
                <Footer layout={layout}>
                  <Button onClick={action.onClick}>{action.label}</Button>
                </Footer>
              )}
            </ModalElement>
          </Container>
        </FocusLock>
      )}
    </>,
    widgetMainDocument.querySelector("body")!
  );
};

const Container = styled.div<{ topOffset: string }>`
  z-index: 10;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: ${({ topOffset }) => topOffset};
`;

interface ModalElementProps {
  isVisible: boolean;
  animations: AnimationsConfig;
}

const ModalElement = styled.div<ModalElementProps>`
  animation: ${({ animations, isVisible }) =>
      isVisible
        ? css`
            ${animations.open} ${animations.openDuration}ms
          `
        : css`
            ${animations.close} ${animations.closeDuration}ms
          `}
    ease forwards;
  background: ${({ theme }) => theme.defaultBackground};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  visibility: hidden;
`;

const Header = styled.header`
  background: ${({ theme }) => theme.mobileModal.backgroundColor};
  display: flex;
  flex-basis: 60px;
  flex-shrink: 0;
  position: relative;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-top: 15px;
`;

const CloseButton = styled(Button)<{ layout?: LayoutType }>`
  max-height: 26px;
  max-width: 26px;
  padding: 0;
  position: absolute;
  right: 15px;
  top: ${({ layout }) => (layout === LayoutType.External ? "30px" : "17px")};
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const Footer = styled.footer<{ layout?: LayoutType }>`
  align-items: center;
  background: ${({ theme }) => theme.mobileModal.footerBackgroundColor};
  box-sizing: border-box;
  display: flex;
  flex-basis: 88px;
  justify-content: flex-end;
  padding: 20px;

  button {
    width: 200px;
    ${({ layout, theme }) =>
      layout === LayoutType.External
        ? `
        font-size: 13px;
        font-family: ${theme.heavyFont};
        height: 48px;
         border-radius: ${theme.ovalBorderRadius};
        margin: 0 auto;
        width: 100%;`
        : `
        width: 200px;
        `}
  }
`;

export default MobileModal;
