import { forwardRef, useCallback, useState } from "react";
import FlightFromIcon from "../../../../../customIcons/FlightFrom";
import {
  AutocompleteAirport,
  AutocompleteAirportSuggestion,
} from "../../../../../types/Autocomplete";
import MobileModal from "../../../../shared/MobileModal";
import MobileFields from "./MobileFields";
import TextField from "../../../../shared/TextField";
import { useTranslation } from "../../../../../i18n";
import { useWizardContext } from "../../../../../contexts/flights/WizardContext";

interface Props {
  error?: string;
  inputLabel: string;
  onSelect(suggestion: AutocompleteAirport, selectedByHand?: boolean): void;
  value: AutocompleteAirportSuggestion;
  scrollControlled?: boolean;
}

const MobileAutocomplete = forwardRef<HTMLInputElement, Props>(
  ({ error, inputLabel, onSelect, scrollControlled, value }, ref) => {
    const { t } = useTranslation();
    const { stopWizard, isWizard, mobileModalAnimations } = useWizardContext();

    const [isOpened, setIsOpened] = useState(false);
    const closeModal = useCallback(() => {
      setIsOpened(false);
      stopWizard();
    }, [stopWizard]);

    const selectSuggestion = useCallback(
      (suggestion: AutocompleteAirport) => {
        onSelect(suggestion, true);
        setIsOpened(false);
      },
      [onSelect]
    );

    return (
      <>
        <TextField
          ref={ref}
          hasError={!!error}
          label={inputLabel}
          onChange={() => {}}
          onFocus={() => setIsOpened(true)}
          icon={<FlightFromIcon />}
          name="departureAirportCode"
          value={value.name}
        />
        <MobileModal
          action={{ label: t("tix_search_form_continue"), onClick: closeModal }}
          headerTitle={t("tix_search_form_departure_title")}
          headerIcon={<FlightFromIcon />}
          isOpened={isOpened}
          onClose={closeModal}
          style={{ header: { flexBasis: "56px" }, content: { overflow: "hidden" } }}
          scrollControlled={scrollControlled}
          animations={mobileModalAnimations}
          isWizard={isWizard}
        >
          <MobileFields label={inputLabel} onSelect={selectSuggestion} />
        </MobileModal>
      </>
    );
  }
);

export default MobileAutocomplete;
