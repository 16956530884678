import { FormikErrors, FormikTouched } from "formik";
import { useCallback } from "react";
import { CustomDatePickerSource } from "../../../../enums/CustomDatePicker";
import { HotelsSearchParams } from "../../../../types/HotelsSearchParams";
import { InputKey, useWizardContext } from "../../../../contexts/hotels/WizardContext";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";
import CustomDateFields from "../../../CustomDayPicker/CustomDateFields";

interface Props {
  errors: FormikErrors<HotelsSearchParams>;
  touched: FormikTouched<HotelsSearchParams>;
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  values: HotelsSearchParams;
}

const HotelDateField = ({ errors, touched, onChange, values }: Props) => {
  const { inputRefs, isWizard, mobileModalAnimations, setInputRef, stopWizard } =
    useWizardContext();

  const handleDayClick = useCallback(
    (date: Date, source: CustomDatePickerSource, isSelectingFirstDay: boolean) => {
      if (source === CustomDatePickerSource.FROM_DATE || isSelectingFirstDay) {
        dispatchCustomEvent({
          widgetEvent: WidgetGATriggerEvents.HotelsFromDateSelected,
          details: { date },
        });
        onChange({ checkInDate: date, checkOutDate: undefined }, false);
      } else {
        dispatchCustomEvent({
          widgetEvent: WidgetGATriggerEvents.HotelsToDateSelected,
          details: { date },
        });
        onChange({ checkOutDate: date });
        if (isWizard) {
          inputRefs.current.roomCount?.click();
        }
      }
    },
    [inputRefs, isWizard, onChange]
  );

  return (
    <CustomDateFields
      ref={setInputRef(InputKey.CheckInDate)}
      onChange={handleDayClick}
      shouldShowRange={true}
      errors={errors}
      touched={touched}
      values={values}
      fromPlaceholderLabel="tix_search_form_from_date"
      toPlaceholderLabel="tix_search_form_to_date"
      fromFieldName="checkInDate"
      toFieldName="checkOutDate"
      mobileModalAnimations={mobileModalAnimations}
      onMobileModalClose={stopWizard}
    />
  );
};

export default HotelDateField;
