// Parsers shared between domains like package + hotels or package + flights
import { HotelsSearchParamsRoom } from "../../types/HotelsSearchParams";

interface ParsableRoom {
  adults: string;
  childrenAge?: string | string[];
}

const isStringOrNumber = (val: any): boolean => {
  return typeof val === "string" || typeof val === "number";
};

const isNumeric = (val: any): boolean => {
  return isStringOrNumber(val) && !Number.isNaN(Number(val));
};


const areChildrenValid = (children: any): boolean => {
  if (!children) return true;
  if (Array.isArray(children)) {
    return children.every(isNumeric);
  } else if (typeof children === "string") {
    const splitted = children.split(",");
    return splitted.every(isNumeric);
  }
  return false;
};

const areRoomsValidToMap = (rooms: any): rooms is ParsableRoom[] => {
  return (
    Array.isArray(rooms) &&
    (rooms as any[]).every((room) => {
      const isAdultValid = !!room.adults && isNumeric(room.adults);
      const areChildrenAgeValid = areChildrenValid(room.childrenAge);
      return isAdultValid && areChildrenAgeValid;
    })
  );
};

export const parseRoomsFromQueryParam = (rooms: any): HotelsSearchParamsRoom[] | undefined => {
  if (!rooms || !areRoomsValidToMap(rooms)) return undefined;
  return rooms.map((room) => ({
    adults: Number(room.adults),
    childrenAge: !room.childrenAge
      ? []
      : Array.isArray(room.childrenAge)
      ? room.childrenAge.map(Number)
      : room.childrenAge.split(",").map(Number),
  }));
};
