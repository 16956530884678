import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTheme } from "styled-components";
import DoubleMonthDropdown from "./ContainerCalendars/DoubleMonthDropdown";
import SingleMonthDropdown from "./ContainerCalendars/SingleMonthDropdown";
import MonthListModal from "./ContainerCalendars/MonthListModal";
import { CustomDatePickerSource } from "../../enums/CustomDatePicker";
import useMedia from "../../hooks/useMedia";
import { isSelectingFirstDay } from "../../utils/calendarUtils";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import useInitialMonth from "../../hooks/useIntialMonth";
import { useSearchFormWithFocusScroll } from "../../hooks/useSearchFormWithFocusScroll";
import { MobileModalAnimations } from "../../types/MobileModal";
import { RectReadOnly } from "react-use-measure";

enum CalendarTypes {
  DOUBLE_MONTH = "double_month",
  SINGLE_MONTH = "single_month",
  MONTHS_LIST = "months_list",
}

interface Props {
  isFromOpen: boolean;
  setIsFromOpen: (isFromOpen: boolean) => void;
  isToOpen: boolean;
  setIsToOpen: (isToOpen: boolean) => void;
  onChange(date: Date, source: CustomDatePickerSource, isSelectingFirstDay: boolean): void;
  disabledDays: { before: Date; after: Date };
  buttonContainerBounds: RectReadOnly;
  allowSameDaySelection?: boolean;
  fromDate?: Date;
  toDate?: Date;
  shouldShowRange?: boolean;
  mobileLabels?: { from: string; to: string; edit: string };
  scrollControlled?: boolean;
  forcedCalendarType?: CalendarTypes;
  isFlightDatepicker?: boolean;
  mobileModalAnimations?: MobileModalAnimations;
  onMobileModalClose?: () => void;
  isSingleField?: boolean;
}

const CustomDayPicker = ({
  isFromOpen,
  setIsFromOpen,
  isToOpen,
  setIsToOpen,
  onChange,
  disabledDays,
  buttonContainerBounds,
  allowSameDaySelection = false,
  fromDate,
  toDate,
  shouldShowRange,
  mobileLabels,
  scrollControlled,
  forcedCalendarType,
  isFlightDatepicker,
  mobileModalAnimations,
  onMobileModalClose,
  isSingleField,
}: Props) => {
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMinDesktop = useMedia(theme.breakpoints.minDesktop);
  const isOnlyTablet = useMedia(theme.breakpoints.onlyTablet);
  const isMaxMobile = useMedia(theme.breakpoints.maxMobile);
  const calendarType = useMemo(() => {
    if (!!forcedCalendarType) return forcedCalendarType;
    if (isMinDesktop) return CalendarTypes.DOUBLE_MONTH;
    if (isOnlyTablet) return CalendarTypes.SINGLE_MONTH;
    return CalendarTypes.MONTHS_LIST;
  }, [forcedCalendarType, isMinDesktop, isOnlyTablet]);

  const selectionSource = useMemo(() => {
    return isToOpen ? CustomDatePickerSource.TO_DATE : CustomDatePickerSource.FROM_DATE;
  }, [isToOpen]);

  const close = useCallback(() => {
    setIsFromOpen(false);
    setIsToOpen(false);
  }, [setIsFromOpen, setIsToOpen]);

  const closeModal = useCallback(() => {
    close();
    onMobileModalClose?.();
  }, [close, onMobileModalClose]);

  const fromClick = useCallback(() => {
    if (shouldShowRange) setIsToOpen(true);
    setIsFromOpen(false);
  }, [setIsFromOpen, setIsToOpen, shouldShowRange]);

  const toClick = useCallback(() => {
    setIsToOpen(false);
    setIsFromOpen(false);
  }, [setIsFromOpen, setIsToOpen]);

  const onDayClick = useCallback(
    (date: Date) => {
      const firstDaySelected = isSelectingFirstDay(
        date,
        selectionSource,
        allowSameDaySelection,
        fromDate,
        toDate
      );
      onChange(date, selectionSource, firstDaySelected);
      if (selectionSource === CustomDatePickerSource.FROM_DATE) {
        fromClick();
      } else if (!firstDaySelected) {
        toClick();
      }
    },
    [selectionSource, allowSameDaySelection, fromDate, toDate, onChange, fromClick, toClick]
  );

  const flightsDoubleMonthDropdownTitle = useMemo(() => {
    return isToOpen
      ? "tix_search_form_flights_select_return_date"
      : "tix_search_form_flights_select_departure_date";
  }, [isToOpen]);

  const hotelsDoubleMonthDropdownTitle = useMemo(() => {
    return isToOpen
      ? "tix_search_form_hotels_select_checkout_date"
      : "tix_search_form_hotels_select_checkin_date";
  }, [isToOpen]);

  const doubleCalendarTitleLabelKey = useMemo(() => {
    return isFlightDatepicker ? flightsDoubleMonthDropdownTitle : hotelsDoubleMonthDropdownTitle;
  }, [flightsDoubleMonthDropdownTitle, hotelsDoubleMonthDropdownTitle, isFlightDatepicker]);

  useOutsideClick(mainContainerRef, () => {
    if (!isMaxMobile) close();
  });

  const initialMonth = useInitialMonth(selectionSource, disabledDays, fromDate, toDate);

  const renderProperCalendar = useCallback(() => {
    switch (calendarType) {
      case CalendarTypes.DOUBLE_MONTH:
        return (
          (isFromOpen || isToOpen) && (
            <DoubleMonthDropdown
              buttonContainerBounds={buttonContainerBounds}
              titleLabelKey={doubleCalendarTitleLabelKey}
              initialMonth={initialMonth}
              onDayClick={onDayClick}
              isToOpen={isToOpen}
              disabledDays={disabledDays}
              fromDate={fromDate}
              toDate={toDate}
              shouldShowRange={shouldShowRange}
              isSingleField={isSingleField}
            />
          )
        );
      case CalendarTypes.SINGLE_MONTH:
        return (
          (isFromOpen || isToOpen) && (
            <SingleMonthDropdown
              buttonContainerBounds={buttonContainerBounds}
              initialMonth={initialMonth}
              alignRight={isToOpen}
              onDayClick={onDayClick}
              disabledDays={disabledDays}
              fromDate={fromDate}
              toDate={toDate}
              shouldShowRange={shouldShowRange}
            />
          )
        );
      case CalendarTypes.MONTHS_LIST:
        return (
          <MonthListModal
            isFromOpen={isFromOpen}
            isToOpen={isToOpen}
            onDayClick={onDayClick}
            disabledDays={disabledDays}
            fromDate={fromDate}
            toDate={toDate}
            shouldShowRange={shouldShowRange}
            mobileLabels={mobileLabels}
            scrollControlled={scrollControlled}
            close={closeModal}
            mobileModalAnimations={mobileModalAnimations}
          />
        );
    }
  }, [calendarType, isFromOpen, isToOpen, buttonContainerBounds, doubleCalendarTitleLabelKey, initialMonth, onDayClick, disabledDays, fromDate, toDate, shouldShowRange, isSingleField, mobileLabels, scrollControlled, closeModal, mobileModalAnimations]);

  const { handleScrollToSearchFormIfNeeded } = useSearchFormWithFocusScroll();
  useEffect(() => {
    if (isFromOpen || isToOpen) {
      handleScrollToSearchFormIfNeeded(mainContainerRef.current?.childNodes[0] as HTMLElement);
    }
  }, [handleScrollToSearchFormIfNeeded, isFromOpen, isToOpen]);

  return <div ref={mainContainerRef}>{renderProperCalendar()}</div>;
};

export default CustomDayPicker;
