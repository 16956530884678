import { getIn, FormikHelpers, useFormik, setIn } from "formik";
import { useCallback, useMemo } from "react";
import { validationSchema } from "../components/HotelsSearchForm/validationSchema";
import { DEFAULT_ROOMS } from "../constants/sharedConstants";
import { HotelsSearchCriteria } from "../types/HotelsSearchCriteria";
import { HotelsSearchParams } from "../types/HotelsSearchParams";
import { useAppAttributes } from "./useAppAttributes";
import { dispatchCustomEvent } from "../services/widgetEventsService";
import { WidgetDispatchEvents } from "../enums/WidgetEvents";
import { CommunicationType } from "../enums/CommunicationType";
import { useHotelSearchFormEvents } from "./useHotelSearchFormEvents";

type OnSubmit = (
  values: HotelsSearchParams,
  formikHelpers: FormikHelpers<HotelsSearchParams>
) => void | Promise<any>;

export const useHotelsSearchForm = (onSubmit: OnSubmit, searchCriteria?: HotelsSearchCriteria) => {
  const appAttributes = useAppAttributes();

  const { setFieldValue, setFieldTouched, setValues, ...formik } = useFormik<HotelsSearchParams>({
    initialValues: {
      checkInDate: searchCriteria?.checkInDate ? new Date(searchCriteria.checkInDate) : undefined,
      checkOutDate: searchCriteria?.checkOutDate
        ? new Date(searchCriteria.checkOutDate)
        : undefined,
      locationCode: searchCriteria?.locationCode || "",
      locationName: "",
      locationType: (searchCriteria?.locationType || "") as any,
      rooms: searchCriteria?.rooms || DEFAULT_ROOMS,
    },
    onSubmit: (values, formikHelpers) => {
      if (appAttributes.communicationType === CommunicationType.Event) {
        dispatchCustomEvent({ widgetEvent: WidgetDispatchEvents.HotelOnSubmit });
      } else {
        onSubmit(values, formikHelpers);
      }
    },
    validationSchema,
  });
  const syncSetFieldValue = useCallback(
    async (field: string, value: any, shouldValidate?: boolean) => {
      await setFieldValue(field, value, shouldValidate);
      setFieldTouched(field, !!shouldValidate);
    },
    [setFieldTouched, setFieldValue]
  );

  const setFieldsValues = useCallback(
    async (valuesChanges: Record<string, any>, shouldValidate?: boolean) => {
      const entries = Object.entries(valuesChanges);
      if (entries.length === 0) return;
      if (entries.length === 1) {
        syncSetFieldValue(entries[0][0], entries[0][1], shouldValidate);
        return;
      }
      setValues((currentValues) => {
        let newValues = { ...currentValues };
        entries.forEach(([key, value]) => {
          newValues = setIn(newValues, key, value);
        });
        return newValues;
      }, shouldValidate);
    },
    [setValues, syncSetFieldValue]
  );

  const hasErrors = useMemo(() => {
    const errorsKeys = Object.keys(formik.errors);
    return errorsKeys.length > 0 && errorsKeys.some((errorKey) => getIn(formik.touched, errorKey));
  }, [formik.errors, formik.touched]);

  const baseForm = useMemo(() => {
    return {
      ...formik,
      setFieldTouched,
      setFieldValue: syncSetFieldValue,
      setValues,
      setFieldsValues,
    };
  }, [formik, setFieldTouched, syncSetFieldValue, setValues, setFieldsValues]);

  useHotelSearchFormEvents({
    form: baseForm,
  });

  return {
    ...baseForm,
    hasErrors,
  };
};
