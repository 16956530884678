import { useMemo } from "react";
import { PackagesSearchFormProps } from "../../../types/packages/PackagesSearchFormProps";
import styled from "styled-components";
import DatesRangeField from "./PackageDateField";
import { Breakpoint } from "../../../enums/Breakpoint";
import useMedia from "../../../hooks/useMedia";
import { useTranslation } from "../../../i18n";
import Button from "../../shared/Button";
import ErrorMessage from "../../shared/ErrorMessage";
import RoomsCountField from "../../SharedSearchFormFields/Hotels/Tix/RoomsCountField";
import PackageLocationAutocompleteField from "./PackageLocationAutocompleteField";
import DepartureAirportAutocompleteField from "./DepartureAirportAutocompleteField";
import { InputKey, useWizardContext } from "../../../contexts/packages/WizardContext";
// import AirportAutocompleteField from "../../SharedSearchFormFields/Flights/Tix/AirportAutocompleteField";

const PackagesSearchForm = ({
  errors,
  hasErrors,
  onChange,
  onSubmit,
  touched,
  values,
}: PackagesSearchFormProps) => {
  const { t } = useTranslation();
  const isMaxMobile = useMedia(Breakpoint.MaxMobile);
  const isMinTablet = useMedia(Breakpoint.MinTablet);
  const isMaxTablet = useMedia(Breakpoint.MaxTablet);
  const isMinDesktop = useMedia(Breakpoint.MinDesktop);
  const { isWizard, mobileModalAnimations, setInputRef, stopWizard } = useWizardContext();

  const departureAirportAutocompleteError = useMemo(() => {
    if (touched.departureAirportCode && errors.departureAirportCode) {
      return errors.departureAirportCode;
    }
  }, [errors.departureAirportCode, touched.departureAirportCode]);
  const destinationAutocompleteError = useMemo(() => {
    if (touched.destinationCode && errors.destinationCode) {
      return errors.destinationCode;
    }
  }, [errors.destinationCode, touched.destinationCode]);
  const datesError = useMemo(() => {
    if (touched.departureDate && errors.departureDate) {
      return errors.departureDate;
    }
    if (touched.returnDate && errors.returnDate) {
      return errors.returnDate;
    }
  }, [errors.departureDate, errors.returnDate, touched.departureDate, touched.returnDate]);
  const showErrors = useMemo(
    () =>
      isMinDesktop &&
      (departureAirportAutocompleteError || datesError || destinationAutocompleteError),
    [datesError, departureAirportAutocompleteError, destinationAutocompleteError, isMinDesktop]
  );

  return (
    <form onSubmit={onSubmit} noValidate>
      <FieldsContainer>
        <AutocompleteContainer>
          <DepartureAirportAutocompleteField
            errors={errors}
            onChange={onChange}
            touched={touched}
            values={values}
          />
        </AutocompleteContainer>
        <AutocompleteContainer>
          <PackageLocationAutocompleteField
            errors={errors}
            onChange={onChange}
            touched={touched}
            values={values}
          />
        </AutocompleteContainer>
        <DatesContainer>
          <DatesRangeField errors={errors} onChange={onChange} touched={touched} values={values} />
        </DatesContainer>
        <RoomsCountField
          onChange={onChange}
          values={values.rooms}
          onSubmit={onSubmit}
          isWizard={isWizard}
          mobileModalAnimations={mobileModalAnimations}
          setInputRef={setInputRef(InputKey.RoomCount)}
          stopWizard={stopWizard}
        />
        {showErrors && (
          <>
            <AutocompleteContainer>
              {departureAirportAutocompleteError && (
                <AutocompleteErrorMessage>
                  <ErrorMessage error={departureAirportAutocompleteError} />
                </AutocompleteErrorMessage>
              )}
            </AutocompleteContainer>
            <AutocompleteContainer>
              {destinationAutocompleteError && (
                <AutocompleteErrorMessage>
                  <ErrorMessage error={destinationAutocompleteError} />
                </AutocompleteErrorMessage>
              )}
            </AutocompleteContainer>
            <div>
              {datesError && (
                <DatesErrorMessage>
                  <ErrorMessage error={datesError} />
                </DatesErrorMessage>
              )}
            </div>
            <SubmitButton type="submit" onClick={onSubmit}>
              {t("tix_search_form_search_packages")}
            </SubmitButton>
          </>
        )}
      </FieldsContainer>
      {isMaxMobile && hasErrors && (
        <FormErrorMessage>
          <ErrorMessage error="tix_search_form_err_packages_search_form_global" />
        </FormErrorMessage>
      )}
      <SubmitButtonRow>
        {!showErrors && (
          <SubmitButton type="submit" onClick={onSubmit} size={isMinDesktop ? "medium" : "small"}>
            {t("tix_search_form_search_packages")}
          </SubmitButton>
        )}
        <div>
          {isMinTablet && isMaxTablet && hasErrors && (
            <FormErrorMessage>
              <ErrorMessage error="tix_search_form_err_packages_search_form_global" />
            </FormErrorMessage>
          )}
        </div>
      </SubmitButtonRow>
    </form>
  );
};

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${Breakpoint.MinTablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media ${Breakpoint.MinDesktop} {
    gap: 0;
  }
`;

const AutocompleteContainer = styled.div`
  flex-shrink: 0;
  flex-basis: 100%;
  width: 100%;

  @media ${Breakpoint.MinTablet} {
    flex-basis: calc(50% - 5px);
    width: calc(50% - 5px);
  }

  @media ${Breakpoint.MinDesktop} {
    flex-basis: 25%;
    width: 25%;
  }
`;

const AutocompleteErrorMessage = styled.div`
  display: inline-flex;
  margin: 0 0 10px 20px;
`;

const DatesContainer = styled.div`
  @media ${Breakpoint.MinTablet} {
    width: calc(50% - 4.5px);
    flex-basis: calc(50% - 4.5px);
  }

  @media ${Breakpoint.MinDesktop} {
    margin: 0 -1px 9px 0;
    flex-shrink: 0;
    flex-basis: 25%;
    width: 25%;
  }
`;

const DatesErrorMessage = styled.div`
  display: inline-flex;
  margin: 0 0 10px 10px;
`;

const FormErrorMessage = styled.div`
  display: inline-flex;
  margin: 0 0 10px 20px;
`;

const SubmitButtonRow = styled.div`
  @media ${Breakpoint.MinTablet} {
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  @media ${Breakpoint.MinDesktop} {
    margin-top: 1px;

    & > div {
      margin: 0 auto 0 0;
    }
  }
`;

const SubmitButton = styled(Button)`
  margin: 0 0 0 auto;
  width: 160px;
  font-family: ${({ theme }) => theme.heavyFont};
  font-size: 20px;

  @media ${Breakpoint.MaxTablet} {
    font-size: 16px;
  }

  @media ${Breakpoint.MinDesktop} {
    width: 200px;
    height: 48px;
  }
`;

export default PackagesSearchForm;
