import { useMemo } from "react";
import MonthListDayPicker from "../DayPickers/MonthListDayPicker/MonthListDayPicker";
import MobileModal from "../../shared/MobileModal";
import { useTranslation } from "../../../i18n";
import { MobileModalAnimations } from "../../../types/MobileModal";

interface Props {
  isFromOpen: boolean;
  isToOpen: boolean;
  onDayClick(date: Date): void;
  disabledDays: { before: Date; after: Date };
  fromDate?: Date;
  toDate?: Date;
  shouldShowRange?: boolean;
  mobileLabels?: { from: string; to: string; edit: string };
  scrollControlled?: boolean;
  close: () => void;
  mobileModalAnimations?: MobileModalAnimations;
}

const MonthListModal = ({
  isFromOpen,
  isToOpen,
  disabledDays,
  fromDate,
  toDate,
  shouldShowRange,
  mobileLabels,
  scrollControlled,
  close,
  onDayClick,
  mobileModalAnimations,
}: Props) => {
  const { t } = useTranslation();

  const mobileTitle = useMemo(() => {
    if (!mobileLabels) return t("tix_ui_when");
    if (!fromDate) return t(mobileLabels.from);
    if (shouldShowRange && !toDate) return t(mobileLabels.to);
    return t(mobileLabels.edit);
  }, [fromDate, mobileLabels, shouldShowRange, t, toDate]);

  const elementToScrollTo = useMemo(() => {
    return isToOpen ? "end" : "start";
  }, [isToOpen]);

  return (
    <MobileModal
      action={{ label: t("continue"), onClick: close }}
      headerTitle={mobileTitle}
      isOpened={isFromOpen || isToOpen}
      onClose={close}
      scrollControlled={scrollControlled}
      animations={mobileModalAnimations}
    >
      <MonthListDayPicker
        disabledDays={disabledDays}
        fromDate={fromDate}
        toDate={toDate}
        onDayClick={onDayClick}
        shouldShowRange={shouldShowRange}
        scrollToElement={elementToScrollTo}
      />
    </MobileModal>
  );
};

export default MonthListModal;
