import { HotelLocationType } from "../../enums/HotelLocationType";

export const parseLocationCodeFromQueryParam = (locationCode: any): string | undefined => {
  return !locationCode || typeof locationCode !== "string" ? undefined : locationCode;
};

export const parseLocationTypeFromQueryParam = (
  locationType: any
): HotelLocationType | undefined => {
  if (!locationType || typeof locationType !== "string") return undefined;
  const values = Object.values(HotelLocationType).map((locType) => locType.toLowerCase());
  return values.includes(locationType.toLowerCase())
    ? (locationType as HotelLocationType)
    : undefined;
};
