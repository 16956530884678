import { forwardRef } from "react";
import { InputProps } from "react-autosuggest";
import styled from "styled-components";
import { Breakpoint } from "../../../../../enums/Breakpoint";

interface Props extends InputProps<any> {
  error?: string;
  label: string;
  placeholder?: string;
  inputTitle?: string;
}

const InputComponent = forwardRef(({ error, label, inputTitle, ...inputProps }: Props, ref) => {
  return (
    <FieldContainer
      hasError={!!error}
      onFocus={(event) => {
        const input = event.currentTarget?.querySelector("input");
        setTimeout(() => {
          input && input.select();
        });
      }}
    >
      {inputTitle && <InputTitle>{inputTitle}</InputTitle>}
      <input ref={ref} aria-label={label} {...(inputProps as any)} />
    </FieldContainer>
  );
});

const FieldContainer = styled.div<{ direction?: any; hasError: boolean }>`
  font-family: ${({ theme }) => theme.regularFont};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > input {
    background: ${({ theme }) => theme.defaultBackground};
    color: ${({ hasError, theme }) => (hasError ? theme.errorColor : theme.defaultColor)};
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    padding-top: 9px;
    font-family: ${({ theme }) => theme.heavyFont};
    border: none;
    height: 70px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    width: 100%;
    border-radius: ${({ theme }) => theme.ovalBorderRadius};

    &::placeholder {
      color: ${({ hasError, theme }) =>
        hasError ? theme.errorColor : theme.form.textField.placeholderColor};
      opacity: 1;
    }

    @media ${Breakpoint.MinTablet} {
      padding-top: 0;
      height: 80px;
    }
  }
`;

const InputTitle = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 11px;
`;

export default InputComponent;
