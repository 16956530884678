import { forwardRef, useCallback, useState } from "react";
import styled from "styled-components";
import HotelLocationIcon from "../../../../../customIcons/HotelLocation";
import MobileFields from "./MobileFields";
import { useTranslation } from "../../../../../i18n";
import MobileModal from "../../../../shared/MobileModal";
import TextField from "../../../../shared/TextField";
import { useWizardContext } from "../../../../../contexts/hotels/WizardContext";
import { AutocompletePackageLocationSuggestion } from "../../../../../types/Autocomplete";

interface Props {
  error?: string;
  inputLabel: string;
  onSelect(suggestion: AutocompletePackageLocationSuggestion, selectedByHand?: boolean): void;
  value: AutocompletePackageLocationSuggestion;
}

const MobileAutocomplete = forwardRef<HTMLInputElement, Props>(
  ({ error, inputLabel, onSelect, value }: Props, ref) => {
    const { stopWizard, isWizard, mobileModalAnimations } = useWizardContext();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = useCallback(() => {
      setIsOpen(false);
      stopWizard();
    }, [stopWizard]);

    const selectSuggestion = useCallback(
      (suggestion: AutocompletePackageLocationSuggestion) => {
        onSelect(suggestion, true);
        setIsOpen(false);
      },
      [onSelect]
    );

    return (
      <>
        <TextFieldContainer>
          <TextField
            ref={ref}
            hasError={!!error}
            label={inputLabel}
            onChange={() => {}}
            onFocus={() => setIsOpen(true)}
            icon={<HotelLocationIcon />}
            name="destinationCode"
            value={value.name}
          />
        </TextFieldContainer>
        <MobileModal
          action={{ label: t("tix_search_form_continue"), onClick: closeModal }}
          animations={mobileModalAnimations}
          headerTitle={t("tix_search_form_location_title")}
          headerIcon={<HotelLocationIcon />}
          isOpened={isOpen}
          onClose={closeModal}
          style={{ header: { flexBasis: "56px" }, content: { overflow: "hidden" } }}
          isWizard={isWizard}
        >
          <MobileFields label={inputLabel} onSelect={selectSuggestion} />
        </MobileModal>
      </>
    );
  }
);

const TextFieldContainer = styled.div`
  svg {
    left: 23px;
    top: 50%;
  }
`;

export default MobileAutocomplete;
