import { createContext, FC, MutableRefObject, useCallback, useContext, useMemo, useRef, useState } from "react";
import { MobileModalAnimations } from "../../types/MobileModal";
import { SearchFormWizardRefElement } from "../../types/SearchFormWizard";

export enum InputKey {
  CheckInDate = "checkInDate",
  RoomCount = "roomCount",
}

type InputRefs = Record<InputKey, SearchFormWizardRefElement>;

interface WizardContextValue {
  inputRefs: MutableRefObject<InputRefs>;
  setInputRef: (path: InputKey) => (element: SearchFormWizardRefElement) => void;
  mobileModalAnimations: MobileModalAnimations;
  isWizard: boolean;
  startWizard: () => void;
  stopWizard: () => void;
}

const createDefaultValuesForInputKeys = <T extends unknown>(fillValue: T) => ({
  [InputKey.CheckInDate]: fillValue,
  [InputKey.RoomCount]: fillValue,
});

const DEFAULT_INPUT_REFS = createDefaultValuesForInputKeys(null);
const DEFAULT_CONTEXT_VALUE: WizardContextValue = {
  inputRefs: { current: DEFAULT_INPUT_REFS },
  setInputRef: () => () => {},
  mobileModalAnimations: {},
  isWizard: false,
  startWizard: () => {},
  stopWizard: () => {},
};

const WizardContext = createContext<WizardContextValue>(DEFAULT_CONTEXT_VALUE);

export const WizardContextProvider: FC = ({ children }) => {
  const inputRefs = useRef<InputRefs>(DEFAULT_INPUT_REFS);

  const setInputRef = useCallback(
    (key: InputKey) => (element: SearchFormWizardRefElement) => {
      inputRefs.current[key] = element;
    },
    []
  );

  const [isWizard, setIsWizard] = useState(false);
  const startWizard = useCallback(() => setIsWizard(true), []);
  const stopWizard = useCallback(() => setIsWizard(false), []);

  const mobileModalAnimations: MobileModalAnimations = useMemo(
    () => (isWizard ? { open: "slide", close: "slide" } : {}),
    [isWizard]
  );

  return (
    <WizardContext.Provider
      value={{
        inputRefs,
        setInputRef,
        mobileModalAnimations,
        startWizard,
        stopWizard,
        isWizard,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};
export const useWizardContext = () => useContext(WizardContext);
