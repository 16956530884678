import { useCallback, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { AutocompleteValue } from "../types";
import { useTranslation } from "../../../../../i18n";
import MobileModal from "../../../../shared/MobileModal";
import TextField from "../../../../shared/TextField";
import { LayoutType } from "../../../../../enums/LayoutType";
import MobileModalInputHeader from "../../../../shared/MobileModal/MobileModalInputHeader";
import { useHotelAutocompleteSuggestions } from "../../../../../hooks/useHotelAutocompleteSuggestions";
import CircleLoader from "../../../../shared/CircleLoader";
import Suggestions from "./Suggestions";
import { useWizardContext } from "../../../../../contexts/hotels/WizardContext";
import { capitalize } from "../../../../../utils/stringUtils";

interface Props {
  inputLabel: string;
  onSelect(suggestion: AutocompleteValue, selectedByHand?: boolean): void;
  value: AutocompleteValue;
  error?: string;
  inputTitle?: string;
}

const MobileAutocomplete = ({ error, inputLabel, inputTitle, onSelect, value }: Props) => {
  const { t } = useTranslation();
  const { stopWizard, isWizard, mobileModalAnimations } = useWizardContext();

  const [inputValue, setInputValue] = useState("");
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = useCallback(() => {
    stopWizard();
    setIsOpen(false);
  }, [stopWizard]);

  const { fetchSuggestions, isLoading, suggestions } = useHotelAutocompleteSuggestions();
  useEffect(() => {
    if (inputValue.length >= 3) {
      fetchSuggestions(inputValue);
    }
  }, [inputValue, fetchSuggestions]);

  const selectSuggestion = useCallback(
    (suggestion: AutocompleteValue) => {
      onSelect(suggestion, true);
      setInputValue(suggestion.name);
      setIsOpen(false);
    },
    [onSelect]
  );

  return (
    <>
      <TextFieldContainer>
        <InputTitle>{inputTitle}</InputTitle>
        <InputField
          hasError={!!error}
          label={inputLabel}
          onChange={() => {}}
          onFocus={() => setIsOpen(true)}
          name="locationCode"
          value={value.name}
          rounded={false}
          layout={LayoutType.External}
        />
      </TextFieldContainer>
      <MobileModal
        layout={LayoutType.External}
        action={{ label: t("tix_search_form_continue"), onClick: closeModal }}
        animations={mobileModalAnimations}
        headerContent={<MobileModalInputHeader inputTitle={capitalize(inputTitle)} />}
        isOpened={isOpen}
        onClose={closeModal}
        style={{
          header: { flexBasis: "56px" },
          content: {
            overflow: "hidden",
            backgroundColor: theme.mobileModal.backgroundColor,
            padding: "0 20px",
          },
        }}
        isWizard={isWizard}
      >
        <ModalContent>
          <>
            <InputContainer>
              <InputLabel>
                {capitalize(t("tix_search_form_mobile_modal_hotel_destination_title"))}
              </InputLabel>
              <StyledTextField
                autoFocus
                label={value.name}
                name="query"
                onChange={(event) => setInputValue(event.currentTarget.value)}
                value={inputValue}
                placeholder={inputLabel}
              />
            </InputContainer>
            <SuggestionsContainer>
              {isLoading ? (
                <LoaderContainer>
                  <CircleLoader size={60} width={4} />
                </LoaderContainer>
              ) : (
                suggestions.length > 0 && (
                  <Suggestions onSelect={selectSuggestion} suggestions={suggestions} />
                )
              )}
            </SuggestionsContainer>
          </>
        </ModalContent>
      </MobileModal>
    </>
  );
};

const InputContainer = styled.div`
  margin: 13px 20px 10px 20px;
  border-bottom: 1px solid;
`;

const InputLabel = styled.div`
  font-size: 11px;
  line-height: 17px;
`;

const StyledTextField = styled(TextField)`
  padding-top: 5px;
  padding-bottom: 20px;
  > input {
    height: 17px;
    font-size: 13px;
    line-height: 17px;
    padding: 0;
    font-family: ${({ theme }) => theme.heavyFont};
  }
`;

const SuggestionsContainer = styled.div`
  height: 100%;
  overflow: auto;
  > ul > li {
    padding: 10px 0;
  }
`;

const InputTitle = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 11px;
  font-family: ${({ theme }) => theme.regularFont};
  z-index: 1;
`;

const InputField = styled(TextField)`
  > input {
    padding-top: 10px;
    border-radius: ${({ theme }) => theme.ovalBorderRadius};
  }
`;

const TextFieldContainer = styled.div`
  position: relative;
  svg {
    left: 23px;
    top: 50%;
  }
`;

const LoaderContainer = styled.div`
  flex-grow: 1;
  padding-top: 50px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  font-family: ${({ theme }) => theme.regularFont};
  background-color: ${({ theme }) => theme.defaultBackground};
  border-radius: ${({ theme }) => theme.ovalBorderRadius};
`;

export default MobileAutocomplete;
