import { FormikErrors, FormikTouched } from "formik";
import { useCallback } from "react";
import { CustomDatePickerSource } from "../../../../enums/CustomDatePicker";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";
import { PackagesSearchParams } from "../../../../types/packages/PackagesSearchParams";
import { InputKey, useWizardContext } from "../../../../contexts/packages/WizardContext";
import ExternalLayoutInputDateFields from "../../../CustomDayPicker/ExternalLayout/ExternalLayoutInputDateFields";

const DEFAULT_ALIGN_LEFT = -5;

interface Props {
  errors: FormikErrors<PackagesSearchParams>;
  touched: FormikTouched<PackagesSearchParams>;
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  values: PackagesSearchParams;
}

const HotelDateField = ({ errors, touched, onChange, values }: Props) => {
  const { inputRefs, isWizard, mobileModalAnimations, setInputRef, stopWizard } =
    useWizardContext();

  const handleDayClick = useCallback(
    (date: Date, source: CustomDatePickerSource, isSelectingFirstDay: boolean) => {
      if (source === CustomDatePickerSource.FROM_DATE || isSelectingFirstDay) {
        dispatchCustomEvent({
          widgetEvent: WidgetGATriggerEvents.PackagesDepartureDateSelected,
          details: { date },
        });
        onChange({ departureDate: date, returnDate: undefined }, false);
      } else {
        dispatchCustomEvent({
          widgetEvent: WidgetGATriggerEvents.PackagesReturnDateSelected,
          details: { date },
        });
        onChange({ returnDate: date });
        if (isWizard) {
          inputRefs.current.roomCount?.click();
        }
      }
    },
    [inputRefs, isWizard, onChange]
  );

  return (
    <ExternalLayoutInputDateFields
      ref={setInputRef(InputKey.DepartureDate)}
      onChange={handleDayClick}
      shouldShowRange={true}
      errors={errors}
      touched={touched}
      values={values}
      fromPlaceholderLabel="tix_search_form_package_departure_date"
      toPlaceholderLabel="tix_search_form_package_return_date"
      inputTitleLabel="tix_search_form_packages_calendar_title"
      fromFieldName="departureDate"
      toFieldName="returnDate"
      alignLeftPx={DEFAULT_ALIGN_LEFT}
      mobileModalAnimations={mobileModalAnimations}
      onMobileModalClose={stopWizard}
      fromMobileTitleLabelKey="tix_search_form_mobile_modal_packages_calendar_departure_title"
      toMobileTitleLabelKey="tix_search_form_mobile_modal_packages_calendar_return_title"
      isWizard={isWizard}
    />
  );
};

export default HotelDateField;
