import { AutocompletePackageLocation } from "../../types/Autocomplete";
import { ApiAction, ApiActionName } from "./ApiAction";
import qs from "qs";

interface Params {
  query: string;
}

export type GetAutocompletePackageLocationsResponse = AutocompletePackageLocation[];

export function getAutocompletePackageLocations(params: Params): ApiAction {
  return {
    method: "get",
    name: ApiActionName.GetAutocompletePackageLocations,
    url: `${process.env.REACT_APP_PACKAGE_LOCATIONS_AUTOCOMPLETE_API_URL}?${qs.stringify(params)}`,
  };
}
