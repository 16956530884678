import { useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useRequest } from "../../api";
import {
  getAutocompletePackageLocations,
  GetAutocompletePackageLocationsResponse,
} from "../../api/actions/getAutocompletePackageLocations";
import { useTranslation } from "../../i18n";
import { usePushErrorLog } from "../usePushErrorLog";
import { AutocompletePackageLocation, AutocompletePackageLocationSuggestion } from "../../types/Autocomplete";
import { dispatchCustomEvent } from "../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../enums/WidgetEvents";
import { PackageLocationType } from "../../enums/packages/PackageLocationType";

const LOAD_DELAY = 100;

const TYPE_LABELS = {
  [PackageLocationType.City]: "tix_search_form_autocomplete_city",
  [PackageLocationType.Hotel]: null,
  [PackageLocationType.Region]: "tix_search_form_autocomplete_region",
};

export const usePackageAutocompleteSuggestions = () => {
  const { t } = useTranslation();
  const request = useRequest();
  const logError = usePushErrorLog();
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<AutocompletePackageLocationSuggestion[]>([]);

  const createDescription = useCallback(
    (suggestion: AutocompletePackageLocation) => {
      const typeLabel = TYPE_LABELS[suggestion.type];
      const typePart = suggestion.accommodationType?.name || (!!typeLabel ? t(typeLabel) : null);
      return [typePart, suggestion.city?.name, suggestion.region?.name].filter(Boolean).join(" - ");
    },
    [t]
  );

  const fetchSuggestions = useDebouncedCallback(async (query: string) => {
    setIsLoading(true);
    try {
        dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.AutocompleteHotelsRequest });
        const locations = await request<GetAutocompletePackageLocationsResponse>(
          getAutocompletePackageLocations({ query })
        );
        const suggestions = locations.map((suggestion) => ({
          code: suggestion.referenceId,
          name: suggestion.name,
          type: suggestion.type,
          description: createDescription(suggestion),
        }));
        setSuggestions(suggestions);
    } catch (error) {
      logError(error, {
        customMessage: "Error during fetching package location autocomplete suggestions",
      });
    } finally {
      setIsLoading(false);
    }
  }, LOAD_DELAY);

  const clearSuggestions = useCallback(() => {
    setSuggestions([]);
  }, []);

  return {
    clearSuggestions,
    fetchSuggestions,
    isLoading,
    suggestions,
  };
};
