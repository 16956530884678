import { forwardRef, useCallback, useState } from "react";
import styled from "styled-components";
import FlightFromIcon from "../../../../../customIcons/FlightFrom";
import FlightToIcon from "../../../../../customIcons/FlightTo";
import SwapMobileIcon from "../../../../../customIcons/SwapMobile";
import { AutocompleteAirport } from "../../../../../types/Autocomplete";
import Button from "../../../../shared/Button";
import MobileModal from "../../../../shared/MobileModal";
import { AutocompleteValue } from "../types";
import MobileFields from "./MobileFields";
import TextField from "../../../../shared/TextField";
import { useTranslation } from "../../../../../i18n";
import { useWizardContext } from "../../../../../contexts/flights/WizardContext";

interface Props {
  direction: "departure" | "destination";
  error?: string;
  inputLabel: string;
  onSelect(suggestion: AutocompleteAirport, selectedByHand?: boolean): void;
  onSwap?(): void;
  value: AutocompleteValue;
  scrollControlled?: boolean;
}

const MobileAutocomplete = forwardRef<HTMLInputElement, Props>(
  ({ direction, error, inputLabel, onSelect, onSwap, value, scrollControlled }, ref) => {
    const { t } = useTranslation();
    const { stopWizard, isWizard, mobileModalAnimations } = useWizardContext();

    const [isOpened, setIsOpened] = useState(false);
    const closeModal = useCallback(() => {
      setIsOpened(false);
      stopWizard();
    }, [stopWizard]);

    const selectSuggestion = useCallback(
      (suggestion: AutocompleteAirport) => {
        onSelect(suggestion, true);
        setIsOpened(false);
      },
      [onSelect]
    );

    return (
      <>
        <TextField
          ref={ref}
          hasError={!!error}
          label={inputLabel}
          onChange={() => {}}
          onFocus={() => setIsOpened(true)}
          icon={direction === "departure" ? <FlightFromIcon /> : <FlightToIcon />}
          name={direction}
          value={value.name}
        />
        {onSwap && (
          <SwapButton color="transparent" onlyIcon onClick={onSwap}>
            <SwapMobileIcon />
          </SwapButton>
        )}
        <MobileModal
          action={{ label: t("tix_search_form_continue"), onClick: closeModal }}
          headerTitle={
            direction === "departure"
              ? t("tix_search_form_departure_title")
              : t("tix_search_form_destination_title")
          }
          headerIcon={direction === "departure" ? <FlightFromIcon /> : <FlightToIcon />}
          isOpened={isOpened}
          onClose={closeModal}
          style={{ header: { flexBasis: "56px" }, content: { overflow: "hidden" } }}
          scrollControlled={scrollControlled}
          animations={mobileModalAnimations}
          isWizard={isWizard}
        >
          <MobileFields label={inputLabel} onSelect={selectSuggestion} />
        </MobileModal>
      </>
    );
  }
);

const SwapButton = styled(Button)`
  padding: 0;
  position: absolute;
  right: 20px;
  top: 33px;
  width: 32px;
  z-index: 1;
`;

export default MobileAutocomplete;
