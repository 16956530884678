import { format } from "date-fns";
import qs from "qs";
import {
  PackagesSearchCriteriaConstructorParams,
  PackagesSearchCriteriaPossibleRoomTypes,
  PackagesSearchParamsRoom,
} from "./PackagesSearchParams";
import { parseRoomsFromQueryParam } from "../../utils/queryParamParsers/sharedQueryParamParsers";
import { isEqual } from "../../utils/isEqual";
import { PackageLocationType } from "../../enums/packages/PackageLocationType";
import { parseDateFromQueryParam } from "../../utils/queryParamParsers/commonQueryParamParsers";
import { DATE_FORMAT } from "../../constants/hotelConstants";
import { DEFAULT_ROOMS } from "../../constants/sharedConstants";

export class PackagesSearchCriteria {
  departureDate?: Date;
  returnDate?: Date;
  departureAirportCode: string;
  destinationCode?: string;
  destinationType: PackageLocationType | "";
  rooms: PackagesSearchParamsRoom[];

  constructor(searchParams: Partial<PackagesSearchCriteriaConstructorParams>) {
    this.departureDate = parseDateFromQueryParam(searchParams.departureDate);
    this.returnDate = parseDateFromQueryParam(searchParams.returnDate);
    this.departureAirportCode = this.parseLocationCodeFromQueryParam(
      searchParams.departureAirportCode
    );
    this.destinationCode = this.parseLocationCodeFromQueryParam(searchParams.destinationCode);
    this.destinationType = this.parseLocationTypeFromQueryParam(searchParams.destinationType);
    this.rooms = this.parseRooms(searchParams.rooms);
  }

  toQueryString(): string {
    return qs.stringify(
      {
        ...(!!this.departureDate ? { departureDate: format(this.departureDate, DATE_FORMAT) } : {}),
        ...(!!this.returnDate ? { returnDate: format(this.returnDate, DATE_FORMAT) } : {}),
        ...(this.departureAirportCode ? { departureAirportCode: this.departureAirportCode } : {}),
        ...(this.destinationCode ? { destinationCode: this.destinationCode } : {}),
        ...(this.destinationType ? { destinationType: this.destinationType } : {}),
        ...(this.rooms
          ? {
              rooms: this.rooms.map((room) => {
                return room.childrenAge.length > 0
                  ? { adults: room.adults, childrenAge: room.childrenAge.join(",") }
                  : { adults: room.adults };
              }),
            }
          : {}),
      },
      { encode: false }
    );
  }

  isEqual(obj: { [key: string]: any }): boolean {
    return isEqual(this, obj);
  }

  private parseRooms(rooms?: PackagesSearchCriteriaPossibleRoomTypes[]) {
    return parseRoomsFromQueryParam(rooms) || DEFAULT_ROOMS;
  }

  private parseLocationCodeFromQueryParam = (locationCode: unknown): string => {
    return !locationCode || typeof locationCode !== "string" ? "" : locationCode;
  };

  private parseLocationTypeFromQueryParam(paramLocationType: unknown): PackageLocationType | "" {
    if (!paramLocationType || typeof paramLocationType !== "string") return "";
    const values = Object.values(PackageLocationType).map((packageLocationType) =>
      packageLocationType.toLowerCase()
    );
    return values.includes(paramLocationType.toLowerCase())
      ? (paramLocationType as PackageLocationType)
      : "";
  }
}
