import { FormikErrors, FormikTouched } from "formik";
import { lazy, Suspense, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Breakpoint } from "../../../../enums/Breakpoint";
import useMedia from "../../../../hooks/useMedia";
import { useTranslation } from "../../../../i18n";
import { PackagesSearchParams } from "../../../../types/packages/PackagesSearchParams";
import { useWizardContext, InputKey } from "../../../../contexts/packages/WizardContext";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";
import { AutocompletePackageLocationSuggestion } from "../../../../types/Autocomplete";
const DesktopAutocomplete = lazy(() => import("./DesktopAutocomplete"));
const MobileAutocomplete = lazy(() => import("./MobileAutocomplete"));

interface Props {
  errors: FormikErrors<PackagesSearchParams>;
  touched: FormikTouched<PackagesSearchParams>;
  onChange(
    valuesChanges: Partial<Record<keyof PackagesSearchParams, unknown>>,
    shouldValidate?: boolean
  ): void;
  values: PackagesSearchParams;
}

const PackageLocationAutocompleteField = ({ errors, onChange, touched, values }: Props) => {
  const { t } = useTranslation();
  const inputLabel = useMemo(() => t("tix_search_form_packages_destination_autocomplete_placeholder"), [t]);
  const titleLabel = useMemo(() => t("tix_search_form_packages_destination_autocomplete_title"), [t]);
  const isMaxMobile = useMedia(Breakpoint.MaxMobile);

  const { startWizard, inputRefs, setInputRef } = useWizardContext();

  const error = useMemo(() => {
    if (touched.destinationCode) {
      return errors.destinationCode;
    }
  }, [errors.destinationCode, touched.destinationCode]);

  const selectSuggestion = useCallback(
    (suggestion: AutocompletePackageLocationSuggestion, selectedByHand?: boolean) => {
      onChange({
        destinationCode: suggestion.code,
        destinationType: suggestion.type,
        destinationName: suggestion.name,
      });
      dispatchCustomEvent({
        widgetEvent: WidgetGATriggerEvents.PackagesDestinationSelected,
        details: { suggestion },
      });
      if ((selectedByHand || isMaxMobile) && !values.departureDate) {
        startWizard();
        inputRefs.current.departureDate?.focus();
      }
    },
    [inputRefs, isMaxMobile, onChange, startWizard, values.departureDate]
  );

  const value = useMemo(() => {
    return {
      code: values.destinationCode,
      name: values.destinationName || "",
      type: values.destinationType,
    };
  }, [values.destinationCode, values.destinationType, values.destinationName]);

  return (
    <Container>
      <Suspense fallback={null}>
        {isMaxMobile ? (
          <MobileAutocomplete
            error={error}
            inputLabel={inputLabel}
            inputTitle={titleLabel}
            onSelect={selectSuggestion}
            value={value}
            ref={setInputRef(InputKey.DestinationCode)}
          />
        ) : (
          <DesktopAutocomplete
            error={error}
            inputLabel={inputLabel}
            inputTitle={titleLabel}
            onSelect={selectSuggestion}
            value={value}
            ref={setInputRef(InputKey.DestinationCode)}
          />
        )}
      </Suspense>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
`;

export default PackageLocationAutocompleteField;
