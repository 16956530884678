import { FC } from "react";
import { RenderSuggestionsContainerParams } from "react-autosuggest";
import styled from "styled-components";

interface Props extends RenderSuggestionsContainerParams {
  isFirstHighlighted: boolean;
}

const SuggestionsContainer: FC<Props> = ({ children, containerProps, isFirstHighlighted }) => {
  return (
    <Container isFirstHighlighted={isFirstHighlighted} {...containerProps}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ isFirstHighlighted: boolean }>`
  box-sizing: border-box;
  position: absolute;
  top: 50px;
  left: -20px;
  max-height: 283px;
  max-width: calc(100% + 40px);
  min-width: calc(100% + 40px);
  overflow: auto;
  white-space: nowrap;
  z-index: 9;

  &::before {
    content: "";
    opacity: 1;
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 13px 13px 13px;
    border-color: transparent transparent
      ${({ isFirstHighlighted, theme }) =>
        isFirstHighlighted
          ? theme.form.selectField.defaultItemHoverBackground
          : theme.defaultBackground}
      transparent;
    background: transparent;
    left: 50%;
    transform: translateX(-50%);
  }

  & > ul.react-autosuggest__suggestions-list {
    background: ${({ theme }) => theme.defaultBackground};
    box-shadow: 0px 0px 20px ${({ theme }) => theme.form.dropdown.boxShadow};
    list-style: none;
    margin: 13px 20px 20px 20px;
    padding: 0;
    overflow: auto;
    max-height: 250px;

    li.react-autosuggest__suggestion {
      background: ${({ theme }) => theme.defaultBackground};
      cursor: pointer;

      &[aria-selected="true"] {
        background: ${({ theme }) => theme.form.selectField.defaultItemHoverBackground};
      }
    }
  }
`;

export default SuggestionsContainer;
