import { format } from "date-fns";
import qs from "qs";
import { DATE_FORMAT } from "../constants/hotelConstants";
import { HotelLocationType } from "../enums/HotelLocationType";
import { HotelsSearchParams, HotelsSearchParamsRoom } from "../types/HotelsSearchParams";
import { isEqual } from "../utils/isEqual";
import {
  HotelsSearchCriteriaConstructorParams,
  HotelsSearchCriteriaPossibleRoomTypes,
} from "./HotelsSearchParams";
import { parseDateFromQueryParam } from "../utils/queryParamParsers/commonQueryParamParsers";
import { parseRoomsFromQueryParam } from "../utils/queryParamParsers/sharedQueryParamParsers";
import {
  parseLocationCodeFromQueryParam,
  parseLocationTypeFromQueryParam,
} from "../utils/queryParamParsers/hotelQueryParamParsers";
import { DEFAULT_ROOMS } from "../constants/sharedConstants";

export class HotelsSearchCriteria {
  checkInDate?: Date;
  checkOutDate?: Date;
  locationCode: string;
  locationType: HotelLocationType | "";
  rooms: HotelsSearchParamsRoom[];

  constructor(searchParams: Partial<HotelsSearchCriteriaConstructorParams>) {
    this.checkInDate = parseDateFromQueryParam(searchParams.checkInDate);
    this.checkOutDate = parseDateFromQueryParam(searchParams.checkOutDate);
    this.locationCode = parseLocationCodeFromQueryParam(searchParams.locationCode) || "";
    this.locationType = parseLocationTypeFromQueryParam(searchParams.locationType) || "";
    this.rooms = this.parseRooms(searchParams.rooms);
  }

  toQueryString(): string {
    const { checkInDate, checkOutDate, rooms, locationCode, locationType } = this;
    return qs.stringify(
      {
        ...(!!checkInDate ? { checkInDate: format(checkInDate, DATE_FORMAT) } : {}),
        ...(!!checkOutDate ? { checkOutDate: format(checkOutDate, DATE_FORMAT) } : {}),
        ...(locationCode ? { locationCode } : {}),
        ...(locationType ? { locationType } : {}),
        ...(rooms
          ? {
              rooms: rooms.map((room) => {
                return room.childrenAge.length > 0
                  ? { adults: room.adults, childrenAge: room.childrenAge.join(",") }
                  : { adults: room.adults };
              }),
            }
          : {}),
      },
      { encode: false }
    );
  }

  toSearchParams(): HotelsSearchParams {
    return {
      checkInDate: this.checkInDate,
      checkOutDate: this.checkOutDate,
      locationCode: this.locationCode,
      locationType: this.locationType,
      rooms: this.rooms,
    };
  }

  toFormData() {
    return {
      checkInDate: format(this.checkInDate!, DATE_FORMAT),
      checkOutDate: format(this.checkOutDate!, DATE_FORMAT),
      locationCode: this.locationCode,
      locationType: this.locationType,
      rooms: this.rooms,
    };
  }

  isEqual(obj: { [key: string]: any }): boolean {
    return isEqual(this, obj);
  }

  private parseRooms(rooms?: HotelsSearchCriteriaPossibleRoomTypes[]) {
    return parseRoomsFromQueryParam(rooms) || DEFAULT_ROOMS;
  }
}
