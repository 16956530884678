import { forwardRef } from "react";
import { InputProps } from "react-autosuggest";
import styled from "styled-components";
import FlightFromIcon from "../../../../../customIcons/FlightFrom";

interface Props extends InputProps<any> {
  error?: string;
  label: string;
  placeholder?: string;
}

const InputComponent = forwardRef(
  ({ error, label, ...inputProps }: Props, ref) => {
    return (
      <FieldContainer
        hasError={!!error}
        onFocus={(event) => {
          const input = event.currentTarget?.querySelector("input");
          setTimeout(() => {
            input && input.select();
          });
        }}
      >
        <FlightFromIcon />
        <input ref={ref} aria-label={label} {...(inputProps as any)} />
      </FieldContainer>
    );
  }
);

const FieldContainer = styled.div<{ hasError: boolean }>`
  font-family: ${({ theme }) => theme.regularFont};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    &::after {
      border-right: 1px solid ${({ theme }) => theme.defaultColor};
      content: "";
      height: 20px;
      position: absolute;
      right: 1px;
      top: 14px;
    }
  }

  & > input[type="text"] {
    background: ${({ theme }) => theme.defaultBackground};
    color: ${({ hasError, theme }) => (hasError ? theme.errorColor : theme.defaultColor)};
    padding: 0 42px 0 47px;
    font-size: 14px;
    font-family: inherit;
    border-radius: 28px;
    border: none;
    height: 44px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    width: 100%;

    &::placeholder {
      color: ${({ hasError, theme }) =>
        hasError ? theme.errorColor : theme.form.textField.placeholderColor};
      opacity: 1;
    }

    @media ${({ theme }) => theme.breakpoints.minDesktop} {
      border-radius: 28px 0 0 28px;
      height: 48px;
      padding: 0 42px 0 50px;
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;

    @media ${({ theme }) => theme.breakpoints.minDesktop} {
      left: 20px;
    }
  }
`;

export default InputComponent;
