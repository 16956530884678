import { FormikErrors, FormikTouched } from "formik";
import { forwardRef, lazy, Suspense, useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import useMedia from "../../../../hooks/useMedia";
import { AutocompleteAirport, AutocompleteAirportSuggestion } from "../../../../types/Autocomplete";
import { useTranslation } from "../../../../i18n";
import { WidgetGATriggerEvents } from "../../../../enums/WidgetEvents";
import { dispatchCustomEvent } from "../../../../services/widgetEventsService";
import { PackagesSearchParams } from "../../../../types/packages/PackagesSearchParams";
import { useWizardContext } from "../../../../contexts/packages/WizardContext";
const DesktopAutocomplete = lazy(() => import("./DesktopAutocomplete"));
const MobileAutocomplete = lazy(() => import("./MobileAutocomplete"));

interface Props {
  errors: FormikErrors<PackagesSearchParams>;
  touched: FormikTouched<PackagesSearchParams>;
  onChange: (
    valuesChanges: Partial<Record<keyof PackagesSearchParams, unknown>>,
    shouldValidate?: boolean
  ) => void;
  values: PackagesSearchParams;
  scrollControlled?: boolean;
}

const DepartureAirportAutocompleteField = forwardRef<HTMLInputElement, Props>(
  ({ errors, touched, onChange, values, scrollControlled }, ref) => {
    const { t } = useTranslation();
    const inputLabel = useMemo(() => t("tix_search_form_departure"), [t]);
    const theme = useTheme();
    const isMaxMobile = useMedia(theme.breakpoints.maxMobile);
    const { startWizard, inputRefs } = useWizardContext();

    const selectSuggestion = useCallback(
      (suggestion: AutocompleteAirport, selectedByHand?: boolean) => {
        onChange({
          departureAirportCode: suggestion.code,
          departureAirportName: suggestion.name,
        });
        dispatchCustomEvent({
          widgetEvent: WidgetGATriggerEvents.PackagesDepartureAirportSelected,
          details: { suggestion },
        });
        if ((selectedByHand || isMaxMobile) && !values.destinationCode) {
          startWizard();
          inputRefs.current.destinationCode?.focus();
        }
      },
      [onChange, isMaxMobile, values.destinationCode, startWizard, inputRefs]
    );

    const value = useMemo(() => {
      return {
        code: values.departureAirportCode,
        name: values.departureAirportName || "",
      } as AutocompleteAirportSuggestion;
    }, [values.departureAirportCode, values.departureAirportName]);
    const error = useMemo(() => {
      if (touched.departureAirportCode) {
        return errors.departureAirportCode;
      }
    }, [errors.departureAirportCode, touched.departureAirportCode]);

    return (
      <Container>
        <Suspense fallback={null}>
          {isMaxMobile ? (
            <MobileAutocomplete
              error={error}
              inputLabel={inputLabel}
              onSelect={selectSuggestion}
              value={value}
              ref={ref}
              scrollControlled={scrollControlled}
            />
          ) : (
            <DesktopAutocomplete
              error={error}
              inputLabel={inputLabel}
              onSelect={selectSuggestion}
              value={value}
              ref={ref}
            />
          )}
        </Suspense>
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.minTablet} {
    &:not(:last-child) {
      margin-right: 9px;
    }
  }

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    width: 100%;

    && {
      margin: 0 -1px 9px 0;
    }
  }
`;

export default DepartureAirportAutocompleteField;
