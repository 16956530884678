import { FormikErrors, FormikTouched } from "formik";
import { ChangeEvent, useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import RadioGroupField from "../../shared/RadioGroupField";
import Switcher from "../Switcher";
import {
  FlightsSearchFormValues,
  FlightsSearchFormValuesType as FlightType,
} from "../../../types/FlightsSearchFormValues";
import SearchFormRow from "./SearchFormRow";
import Button from "../../shared/Button";
import useMedia from "../../../hooks/useMedia";
import ErrorMessage from "../../shared/ErrorMessage";
import { useTranslation } from "../../../i18n";
import { WidgetGATriggerEvents } from "../../../enums/WidgetEvents";
import { dispatchCustomEvent } from "../../../services/widgetEventsService";
import { InputKey, useWizardContext } from "../../../contexts/flights/WizardContext";

export const EMPTY_BOUND = {
  departureCode: "",
  departureName: "",
  departureDate: undefined,
  destinationCode: "",
  destinationName: "",
};

const BOUNDS_NUMBER = {
  [FlightType.OneWay]: 1,
  [FlightType.Return]: 2,
  [FlightType.MultiCity]: 2,
};

interface Props {
  errors: FormikErrors<FlightsSearchFormValues>;
  hasErrors: boolean;
  touched: FormikTouched<FlightsSearchFormValues>;
  onChange(valuesChanges: Record<string, any>, shouldValidate?: boolean): void;
  onSubmit(): void;
  values: FlightsSearchFormValues;
  scrollControlled?: boolean;
}

export const FlightsSearchForm = ({
  errors,
  hasErrors,
  touched,
  onChange,
  onSubmit,
  values,
  scrollControlled,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMaxMobile = useMedia(theme.breakpoints.maxMobile);
  const isMinTablet = useMedia(theme.breakpoints.minTablet);
  const isMinDesktop = useMedia(theme.breakpoints.minDesktop);
  const isOnlyTablet = useMedia(theme.breakpoints.onlyTablet);
  const { inputRefs, isWizard } = useWizardContext();

  const typeOptions = useMemo(
    () => [
      { label: t("tix_search_form_return"), value: FlightType.Return },
      { label: t("tix_search_form_oneway"), value: FlightType.OneWay },
      { label: t("tix_search_form_multicity"), value: FlightType.MultiCity },
    ],
    [t]
  );
  const handleTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const bounds =
        event.target.value === FlightType.Return
          ? [
              values.bounds[0],
              {
                departureCode: values.bounds[0].destinationCode,
                departureName: values.bounds[0].destinationName,
                departureDate: values.bounds[1]?.departureDate,
                destinationCode: values.bounds[0].departureCode,
                destinationName: values.bounds[0].departureName,
              },
            ]
          : new Array(BOUNDS_NUMBER[event.target.value as FlightType])
              .fill(null)
              .map((_, index) => values.bounds[index] || EMPTY_BOUND);
      const selectionEvent =
        event.target.value === FlightType.Return
          ? WidgetGATriggerEvents.ReturnSelected
          : event.target.value === FlightType.OneWay
          ? WidgetGATriggerEvents.OneWaySelected
          : WidgetGATriggerEvents.MulticitySelected;
      dispatchCustomEvent({ widgetEvent: selectionEvent });
      onChange({ type: event.target.value, bounds });
    },
    [onChange, values.bounds]
  );

  const onDateSelect = useCallback(
    (rowIndex: number) => () => {
      switch (rowIndex) {
        case 0: {
          if (values.type === FlightType.MultiCity && !values.bounds[1].departureCode) {
            inputRefs.current.secondDepartureCode?.focus();
          } else if (values.type !== FlightType.MultiCity && isWizard) {
            inputRefs.current.extendedSearch?.click();
          }
          break;
        }
        case 1: {
          if (isWizard) {
            inputRefs.current.extendedSearch?.click();
          }
          break;
        }
      }
    },
    [inputRefs, isWizard, values.bounds, values.type]
  );

  const showSubmitButton = useMemo(() => {
    if (isMinDesktop) {
      return !hasErrors && values.type !== FlightType.MultiCity;
    } else if (isMinTablet) {
      return values.type !== FlightType.MultiCity;
    }
    return true;
  }, [isMinDesktop, isMinTablet, hasErrors, values.type]);

  return (
    <form onSubmit={onSubmit} noValidate>
      <Row>
        {isMinTablet ? (
          <RadioGroupField
            inline
            label={t("tix_search_form_flight_type")}
            onChange={handleTypeChange}
            name="type"
            options={typeOptions}
            value={values.type}
          />
        ) : (
          <Switcher
            label={t("tix_search_form_flight_type")}
            onChange={handleTypeChange}
            name="type"
            options={typeOptions}
            value={values.type}
          />
        )}
      </Row>
      <SearchFormRow
        boundsIndexes={values.type === FlightType.Return ? [0, 1] : [0]}
        errors={errors}
        touched={touched}
        isLastRow={values.type !== FlightType.MultiCity}
        onChange={onChange}
        onDateSelect={onDateSelect(0)}
        onSubmit={onSubmit}
        values={values}
        scrollControlled={scrollControlled}
        showExtendedFlightSearch={values.type === FlightType.MultiCity ? isMinTablet : true}
        departureCodeInputKey={InputKey.FirstDepartureCode}
      />
      {values.type === FlightType.MultiCity && (
        <SearchFormRow
          boundsIndexes={[1]}
          errors={errors}
          touched={touched}
          isLastRow
          onChange={onChange}
          onDateSelect={onDateSelect(1)}
          onSubmit={onSubmit}
          scrollControlled={scrollControlled}
          values={values}
          showExtendedFlightSearch={!isMinTablet}
          departureCodeInputKey={InputKey.SecondDepartureCode}
        />
      )}
      {isMaxMobile && hasErrors && (
        <FormErrorMessage>
          <ErrorMessage error="tix_search_form_err_flights_search_form_global" />
        </FormErrorMessage>
      )}
      <SubmitButtonRow>
        {showSubmitButton && (
          <SubmitButton
            type="submit"
            onClick={() => {
              onSubmit();
            }}
            size={isMinDesktop ? "medium" : "small"}
          >
            {t("tix_search_form_search_flights")}
          </SubmitButton>
        )}
        <div>
          {isOnlyTablet && hasErrors && (
            <FormErrorMessage>
              <ErrorMessage error="tix_search_form_err_flights_search_form_global" />
            </FormErrorMessage>
          )}
        </div>
      </SubmitButtonRow>
    </form>
  );
};

const FormErrorMessage = styled.div`
  display: inline-flex;
  margin: 0 0 10px 20px;
`;

const Row = styled.div`
  margin: 0 0 10px 0;

  @media ${({ theme }) => theme.breakpoints.minTablet} {
    margin: 0 0 10px 20px;
  }
`;

const SubmitButton = styled(Button)`
  margin: 0 0 0 auto;
  width: 160px;
  background-color: ${({ theme }) => theme.flightSearchForm.buttonColor};
  font-family: ${({ theme }) => theme.heavyFont};
  font-size: 20px;

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    width: 200px;
    height: 48px;
  }

  @media ${({ theme }) => theme.breakpoints.maxTablet} {
    font-size: 16px;
  }
`;

const SubmitButtonRow = styled.div`
  @media ${({ theme }) => theme.breakpoints.minTablet} {
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    margin-top: 1px;

    & > div {
      margin: 0 auto 0 0;
    }
  }
`;

export default FlightsSearchForm;
