import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { CABIN_CLASS_LABELS } from "../../../constants/flightsConstants";
import { Breakpoint } from "../../../enums/Breakpoint";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import useMedia from "../../../hooks/useMedia";
import { FlightsSearchFormValues } from "../../../types/FlightsSearchFormValues";
import { capitalize } from "../../../utils/stringUtils";
import ExtendedSearchFields from "./ExtendedSearchFields";
import MobileModal from "../../shared/MobileModal";
import Button from "../../shared/Button";
import { useTranslation } from "../../../i18n";
import MobileModalInputHeader from "./MobileModalInputHeader";
import { LayoutType } from "../../../enums/LayoutType";
import { InputKey, useWizardContext } from "../WizardContext";
import { dispatchCustomEvent } from "../../../services/widgetEventsService";
import { WidgetGATriggerEvents } from "../../../enums/WidgetEvents";
import { isSafari } from "../../../utils/browsersUtils";

interface Props {
  onChange: (valuesChanges: Record<string, any>, shouldValidate?: boolean) => any;
  onSubmit: () => void;
  values: FlightsSearchFormValues;
  scrollControlled?: boolean;
  isMulticity?: boolean;
}

const ExtendedFlightSearch = ({
  onChange,
  onSubmit,
  values,
  scrollControlled,
  isMulticity,
}: Props) => {
  const ref = useRef(null);
  const { isWizard, mobileModalAnimations, setInputRef, stopWizard } = useWizardContext();
  const { t } = useTranslation();
  const isMinTablet = useMedia(Breakpoint.MinTablet);
  const theme = useTheme();

  const [isOpened, setIsOpened] = useState(false);
  const close = useCallback(() => {
    if (isOpened) {
      setIsOpened(false);
      stopWizard();
    }
  }, [isOpened, stopWizard]);

  const dropdownClose = useCallback(() => {
    if (isMinTablet) {
      close();
    }
  }, [close, isMinTablet]);
  useOutsideClick(ref, dropdownClose);

  // Button label
  const totalPassengers = useMemo(
    () => values.adults + values.children + values.infants,
    [values.adults, values.children, values.infants]
  );
  const label = useMemo(
    () =>
      `${capitalize(t("tix_search_form_passengers"))} ${totalPassengers}, ${capitalize(
        t(CABIN_CLASS_LABELS[values.cabinClass])
      )}`,
    [t, totalPassengers, values.cabinClass]
  );

  useEffect(() => {
    if (isOpened) {
      dispatchCustomEvent({ widgetEvent: WidgetGATriggerEvents.OpenFlightExtendedSearch });
    }
  }, [isOpened]);

  const submitAction = useMemo(() => (isWizard ? onSubmit : close), [close, onSubmit, isWizard]);

  const handleActionOnClick = useCallback(() => {
    if (isWizard) onSubmit();
    close();
  }, [close, isWizard, onSubmit]);

  return (
    <Container id="extended-flight-search" ref={ref}>
      <DropdownButton
        onClick={() => setIsOpened(!isOpened)}
        title={label}
        type="button"
        ref={setInputRef(InputKey.ExtendedSearch)}
      >
        <InputTitle>{t("tix_search_form_flights_extended_search_title")}</InputTitle>
        <Label>{label}</Label>
      </DropdownButton>
      {isMinTablet ? (
        isOpened && (
          <Dropdown isMulticity={isMulticity}>
            <DropdownInputTitle>
              {t("tix_search_form_flights_extended_search_title")}
            </DropdownInputTitle>
            <DropdownLabel>{label}</DropdownLabel>
            <ExtendedSearchFields onChange={onChange} values={values} />
            <ReadyButton variant="rectangle" onClick={submitAction}>
              {t(isWizard ? "tix_search_form_search_flights" : "tix_search_form_ready")}
            </ReadyButton>
          </Dropdown>
        )
      ) : (
        <MobileModal
          layout={LayoutType.External}
          action={{
            label: t(isWizard ? "tix_search_form_search_flights" : "tix_search_form_continue"),
            onClick: handleActionOnClick,
          }}
          headerContent={
            <MobileModalInputHeader
              inputTitle={capitalize(
                t("tix_search_form_flights_extended_search_mobile_modal_title")
              )}
            />
          }
          isOpened={isOpened}
          onClose={close}
          scrollControlled={scrollControlled}
          animations={mobileModalAnimations}
          isWizard={isWizard}
          style={{
            content: { padding: "0 20px", backgroundColor: theme.mobileModal.backgroundColor },
          }}
        >
          <MobileFieldsContainer>
            <ExtendedSearchFields onChange={onChange} values={values} />
          </MobileFieldsContainer>
        </MobileModal>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.minDesktop} {
    width: 225px;
  }
`;

const DropdownButton = styled.button`
  align-items: center;
  appearance: none;
  background: ${({ theme }) => theme.defaultBackground};
  border: none;
  color: ${({ theme }) => theme.defaultColor};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.regularFont};
  font-size: 13px;
  height: 70px;
  padding-left: 20px;
  position: relative;
  text-align: left;
  width: 100%;
  border-radius: ${({ theme }) => theme.ovalBorderRadius};

  @media ${({ theme }) => theme.breakpoints.minTablet} {
    height: 100%;
  }

  svg {
    left: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media ${({ theme }) => theme.breakpoints.minDesktop} {
      left: 10px;
    }
  }
`;

const Label = styled.span`
  display: block;
  margin-top: 58px;
  max-width: 100%;
  font-family: ${({ theme }) => theme.heavyFont};
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
`;

const DropdownLabel = styled(Label)`
  margin-top: 10px;
  margin-bottom: 20px;
  height: 100%;
  padding-bottom: 19.5px;
  border-bottom: 1px solid;
  ${() => (isSafari() ? "margin-top: 11px" : "")};
`;

const InputTitle = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 11px;
`;

const DropdownInputTitle = styled(InputTitle)`
  top: 15px;
  left: 25px;
`;

const MobileFieldsContainer = styled.div`
  padding: 25px;
  background: ${({ theme }) => theme.defaultBackground};
  min-height: calc(100% - 50px);
  border-radius: ${({ theme }) => theme.ovalBorderRadius};
`;

const Dropdown = styled.div<{ isMulticity?: boolean }>`
  background: ${({ theme }) => theme.defaultBackground};
  box-shadow: 0px 0px 20px ${({ theme }) => theme.form.dropdown.boxShadow};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 25px;
  position: absolute;
  right: unset;
  top: -5px;
  left: -5px;
  width: 360px;
  z-index: 10;

  @media ${Breakpoint.MinDesktop} {
    ${({ isMulticity }) => !!isMulticity && `left: -106px;`}
  }
`;

const ReadyButton = styled(Button)`
  font-family: ${({ theme }) => theme.heavyFont};
  height: 48px;
  margin-top: 24px;
  border-radius: ${({ theme }) => theme.ovalBorderRadius};
  background-color: ${({ theme }) => theme.flightSearchForm.buttonColor};
  font-size: 13px;
  line-height: 17px;
`;

export default ExtendedFlightSearch;
